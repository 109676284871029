import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useEffect, useState, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Add } from "../../assets/new-images/Plus icon.svg";
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import DataTable from '../../components/layout-components/DataTable';
import { deleteScheduleTimeZoneInterval, getTimeZoneIntervalList } from '../../services/SchedulesService';
import CommonUtil from '../../util/CommonUtils';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as Delete } from "../../assets/new-images/Delete Icon.svg";
import { ReactComponent as Edit } from "../../assets/new-images/Edit Icon.svg";
import RightViewLayout from '../../components/RighViewLayout';
import { REACT_CREATE_TIMESLOT } from '../../constants/ReactEndPoints';
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { useSnackbar } from 'notistack';
import CustomDialogBox from '../../components/CustomDialogBox';
import { ReactComponent as ViewIcon } from "../../assets/images/ViewIcon.svg"
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from 'react-i18next';

export default function TimeSlot() {
  const { t } = useTranslation()
  const location = useLocation();
  const [page, setPage] = React.useState(location?.state?.pageNumber ? location?.state?.pageNumber : 1);
  const [delId, setDelId] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize ? location?.state?.pageSize : 10);
  const [pageSize, setPageSize] = useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [schedules, setSchedules] = useState([]);
  const [del, SetDel] = useState(false);

  const [name, setName] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [ids, setIds] = useState([]);
  const [filter, setFilter] = useState(false);
  const [load, setLoad] = useState(true);
  const [change, setChange] = React.useState(false);
  const [reload, setReload] = useState(false);
  const [clearAndRefresh, setClearAndRefresh] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [perViewTimeSlots, setViewTimeSlots] = useState(true);
  const [roles, setRoles] = React.useState(['']);
  const [perAddTimeSlots, setAddTimeSlots] = useState(true);
  const [perDeleteTimeSlots, setDeleteTimeSlots] = useState(true);
  const [perEditTimeSlots, setEditTimeSlots] = useState(true);
  const [perListTimeSlots, setListTimeSlots] = useState(true);

  useEffect(() => {
    if (load && !filter)
      getPermissionsByRoleId(api.decoded().roleId).then((res) => {
        var l = [];
        res.data.data.rolePermissions.map((item, index) => {
          l.push(item.code);
        });
        setRoles(l);
        setViewTimeSlots(l.includes("VIEW_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setAddTimeSlots(l.includes("ADD_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setDeleteTimeSlots(l.includes("ADD_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setEditTimeSlots(l.includes("ADD_TIMESLOT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListTimeSlots(l.includes("TIMESLOT_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("TIMESLOT_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          loadSchedules(page, rowsPerPage, '');
        }

      });

  }, [page, rowsPerPage]);

  useEffect(() => {
    if (filter && !load && reload) {
      loadSchedules(1, rowsPerPage, name);
      setReload(false);
    }
  }, [filter, reload]);

  const columns = [
    {
      field: 'operationName',
      hideable: false,
      headerName: t('AccTime001'),
      flex: 5,
      valueGetter: (params) => params.row.operationName
    },
    {
      field: 'actions',
      headerName: t('COMMON019'),
      type: 'actions',
      flex: 0.6,
      renderCell: (e) => (
        <>
          {e.row.operationName === '24-Hours General' ? <>
            {perViewTimeSlots ? (<Tooltip title={t("COMMON004")}>
              <ViewIcon id="icon-timeslot-view" style={{ cursor: 'pointer' }}
                onClick={() => { openEditPage(e) }}
              /></Tooltip>) : <Box></Box>}
          </> :
            <>
              <GridActionsCellItem
                sx={{ marginRight: { xl: "20px", xs: "10px" } }}
                icon={perEditTimeSlots ? (<Tooltip title={t("COMMON005")}><Edit id="icon-timeslot-edit" style={{ cursor: 'pointer' }}
                  onClick={() => { openEditPage(e) }}
                /></Tooltip>) : <Box></Box>}
              />

              <GridActionsCellItem
                icon={perDeleteTimeSlots ? (<Tooltip title={t("COMMON008")}><Delete id="icon-timeslot-delete" width={'17px'} style={{ cursor: 'pointer' }}
                  onClick={(rowData) => { handleDelete(e) }}
                /></Tooltip>) : <Box></Box>}
              />
            </>}
        </>
      )
    },
  ];

  const DEFAULT_PAGE_SIZE = 10;

  const handleRefresh = () => {
    if (page === 1) {
      setName('');
      setDisabled(true)
      setFilter(false);
      setLoad(true);
      loadSchedules(1, DEFAULT_PAGE_SIZE, '');
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else {
      setName('');
      setDisabled(true)
      setFilter(false);
      setLoad(true);
      setPage(1);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
  }

  const actions = () => (
    <>
      {
        <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
          <Tooltip title={t('ACCORG008')}>
            <Box
              aria-label="refresh"
              onClick={() => handleRefresh()}
              mr={2}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"refresh-Icon"}
            >
              <Refresh style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="refresh"
              onClick={() => handleRefresh()}
              mr={2}
              sx={{ display: { xl: "none", xs: "block" } }}
            >
              <Refresh width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip>
          <Tooltip title={t('ACCORG009')}>
            <Box
              aria-label="filter"
              onClick={() => setOpenFilter(!openFilter)}
              mr={2}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"filter-Icon"}
            >
              <Filter style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="refresh"
              onClick={() => setOpenFilter(!openFilter)}
              mr={2}
              sx={{ display: { xl: "none", xs: "block" } }}
              id={"filter-Icon"}
            >
              <Filter width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip>
          {perAddTimeSlots && (<Tooltip arrow title={t("AccTime014")}>
            <Box
              aria-label="add-box"

              onClick={() => navigate(REACT_CREATE_TIMESLOT, { state: { pageNumber: page, pageSize: rowsPerPage } })}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"add-Icon"}
            >
              <Add style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="add-box"
              onClick={() => navigate(REACT_CREATE_TIMESLOT, { state: { pageNumber: page, pageSize: rowsPerPage } })}
              sx={{ display: { xl: "none", xs: "block" } }}
              id={"add-Icon"}
            >
              <Add width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip>)}
        </Box>
      }
    </>
  );

  const openEditPage = (rowData) => {
    navigate(`/update-time-slot/${rowData.id}`, { state: { id: rowData.id, name: rowData.row.operationName, pageNumber: page, pageSize: rowsPerPage } });
  };

  const loadSchedules = (page, size, name) => {
    setIds([]);
    getTimeZoneIntervalList(page, size, name).then((res) => {
      if (res != null && !CommonUtil.isEmpty(res)
        && res?.data?.code === 'DMSI0000'
        && !CommonUtil.isEmpty(res?.data?.data)
        && res?.data?.data?.timeZone instanceof Array) {
        setTotalRecords(res.data.data.totalCount);
        setSchedules(res.data.data.timeZone);
        setPageSize(res.data.data.pageSize);
      } else {
        setTotalRecords(0);
        setSchedules([]);
      }
      setClearAndRefresh(false);
    });
  };

  // This for Delete function
  const handleDelete = (data) => {
    setOpenDelete(!openDelete);
    setDelId(data.row);
  }

  const handleSubmitDelete = () => {
    const payload = {
      id: delId.id,
      name: delId.operationName
    }
    if (delId.id)
      deleteScheduleTimeZoneInterval(payload).then((res) => {
        if (res?.data?.code === "DMSI0009") {
          enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'success' })
          SetDel(true);
        } else {
          if (res?.data?.code === "DMSE00056")
            enqueueSnackbar((res.data.msg).replace("Schedules", t("AccTime013")), { variant: 'error' })
          else
            enqueueSnackbar((res.data.msg).replace("Schedule", t("AccTime013")), { variant: 'error' })
        }
        setOpenDelete(false);
      })
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadSchedules(page, rowsPerPage, name);
      SetDel(false);
    } else {
      if (del === true) {
        loadSchedules(page - 1, rowsPerPage, name);
        SetDel(false);
      }
    }
  }, [del]);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // This is for filter Components

  const FieldTimeSlotName = (key) => (
    <CustomInput
      id="textfield-time-slot-name"
      placeholder={t('AccTime001')}
      value={name}
      onKeyDown={handleKeyPress}
      size='small'
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      fullWidth
      name='operationName'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 100,
      }}

    />
  );

  const handleChange = (event) => {
    setChange(true);
    setName(event.target.value);
  };

  useEffect(() => {
    if (name === '') {
      setDisabled(true);
      if (change) {
        setFilter(false); setLoad(true);
        if (page === 1 && !clearAndRefresh) loadSchedules(1, rowsPerPage, '');
        else {
          if (!clearAndRefresh)
            setPage(1);
        }
      }
    } else setDisabled(false);
  }, [name]);

  const clearFilter = () => {
    setName('');
    setClearAndRefresh(true)
    setDisabled(true);
    setFilter(false);
    setLoad(true);
    if (page === 1) {
      loadSchedules(1, DEFAULT_PAGE_SIZE, '');
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else setPage(1);
    setRowsPerPage(DEFAULT_PAGE_SIZE);
  }

  const handleSubmitFilter = () => {
    setFilter(true);
    setLoad(false);
    setReload(true)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilter();
    }
  };

  useEffect(() => {
    if (!openFilter && !disabled) {
      handleRefresh();
    }
  }, [openFilter])

  const handlePageChange = (e) => {
    if (filter) {
      setPage(e);
      loadSchedules(e, rowsPerPage, name);
    }
    else {
      setFilter(false);
      setLoad(true);
      setPage(e);
    }
  }

  const handlePageSize = (e) => {
    if (filter) {
      setRowsPerPage(e);
      setPage(1);
      loadSchedules(1, e, name);
    }
    else {
      setFilter(false);
      setLoad(true);
      setRowsPerPage(e);
      setPage(1);
    }
  }

  const onSelectChecxBoxChange = (id) => {
    setIds(id);
  };
  return (
    <>
      {perListTimeSlots ? (<><RightViewLayout
        title={t("AccTime013")}
        actions={actions}
        type='table'
        navigateBack={true}
      />
        <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
          {openFilter && <FilterComponent
            firstField={FieldTimeSlotName()}
            clearFilter={clearFilter}
            handleSubmit={handleSubmitFilter}
            disabled={disabled}
          />}
          <DataTable
            page={page - 1}
            totalRecords={totalRecords}
            rowsPerPage={rowsPerPage}
            rows={schedules}
            columns={columns}
            getRowId={(row) => row.id}
            checkboxSelection={true}
            onSelection={(e) => onSelectChecxBoxChange(e)}
            rowSelectionModel={ids}
            onPageChange={(e) => handlePageChange(e + 1)}
            onPageSizeChange={(e) => handlePageSize(e)
            }
            noRecordText={t("AccTime012")}
          />

        </Box>
      </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={"center"}>{t("AccTime010")}</Typography></Box>)
      }
      <CustomDialogBox
        id="deleteTimeZone"
        open={openDelete}
        customWidth={'420px'}
        title={t("COMMON013")}
        message={t("AccTime011")}
        saveButtonName={t("COMMON008")}
        cancelButtonName={t("COMMON009")}
        onClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
    </>
  );
}
