import axios from 'axios';
import api from '../configurations/http-common';
import { REACT_URL_ACCESS_GROUP_FILTER_V4 } from '../constants/ReactEndPoints';
import CommonUtil from '../util/CommonUtils';

export const postAccessGroup = async (payload) => {
  return api
    .securedAxios()
    .post('/web/access_group', payload);
};

export const createQuickSetup = async (payload) => {
  return api
    .securedAxios()
    .post('/web/access_groups/quick-setup', payload);
};

export const updateAccessGroup = async (id, payload) => {
  return api
    .securedAxios()
    .put('/web/access_groups?' + 'id=' + id, payload);
};

export const getAccessGroup = async (page, size, callBack) => {
  try {
    const res = await axios.get('/web/access_groups?pageNumber=' + page + '&pageSize=' + size);
    callBack(res);
  } catch (error) { }
};

export const deleteAcccessGroupById = async (ids, name) => {
  return api
    .securedAxios()
    .delete('/web/access_groups/' + ids + `?name= ${encodeURIComponent(name)}`);
};

export const getFilterAccessGroup = async (page, size, filter) => {
  if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(size)) {
    return api
      .securedAxios()
      .post(REACT_URL_ACCESS_GROUP_FILTER_V4 + '?page=' + page + '&size=' + size + buildQuery(filter));
  }
  if (!CommonUtil.isEmptyString(page) && !CommonUtil.isEmptyString(size)) {
    return api
      .securedAxios()
      .post('/web/access_groups_filter?page=' + page + '&size=' + size);
  }
};

export const getTimeZoneIntervalList = async (page, size, name) => {
  return api.securedAxios().post('/web/schedules/list?page=' + page + '&size=' + size + '&name=' + name);
};

export const getAccessGroupDoors = async (page, size, accessGroupId) => {
  return api.securedAxios().get('/web/access_group_doors/' + accessGroupId + '/list?pageNumber=' + page + '&pageSize=' + size);
};

export const addDoorsToAccessGroup = async (accessGroupId, payload) => {
  return api.securedAxios().post('/web/access_group_doors/' + accessGroupId, payload);
};

export const deleteAcccessGroupDoorById = async (accessGroupId, doorId, doorName) => {
  return api.securedAxios().delete('/web/access_group_doors/' + accessGroupId + '/doors/' + doorId + "/doorName/" + doorName);
};

export const loadAccessGroupPersons = async (accessGroupId, page, size, filter) => {
  return api.securedAxios().get('/web/access_group_persons/' + accessGroupId + '?page=' + page + '&size=' + size + buildQueryAccGroupPerson(filter));
};

export const addPersonsToAccessGroup = async (accessGroupId, payload) => {
  return api.securedAxios().post('/web/access_groups_persons/' + accessGroupId, payload);
};

export const deleteAcccessGroupPersonById = async (accessGroupId, personId, name) => {
  return api.securedAxios().delete('/web/access_group_persons/' + accessGroupId + '/persons/' + personId + "?name=" + name);
};

function buildQuery(filter) {
  let query = '';

  if (filter.name) {
    query = query + `&name=${encodeURIComponent(filter.name)}`.replace(" ", "");
  }

  if (filter.id) {
    query = query + `&id=${filter.id}`;
  }

  return query;
}

function buildQueryAccGroupPerson(filter) {
  let query = ''
  if (filter.firstName != '') {
    query = query + `&firstName= ${filter.firstName}`
  }
  if (filter.lastName != '') {
    query = query + `&lastName= ${filter.lastName}`
  }
  if (filter.code != '') {
    query = query + `&code= ${filter.code}`
  }
  return query
}