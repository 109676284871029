import { Autocomplete, Box, Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ReactComponent as AccDeviceIcon } from '../../assets/DeviceIcon/accDeviceICon.svg';
import { ReactComponent as AttDeviceIcon } from '../../assets/DeviceIcon/attDeviceIcon.svg';
import Device from '../../assets/DeviceIcon/camIcon.png';
import CustomInput from '../../components/CustomInput';
import Loader from '../../components/Loader';
import SimpleDailogBox from '../../components/SimpleDailogBox';
import TitleBar from '../../components/TitleBar';
import api from '../../configurations/http-common';
import { REACT_URL_DEVICE_LIST_V4 } from '../../constants/ReactEndPoints';
import { bindDevice, filterDevices, findDeviceBySn } from '../../services/DeviceService';
import { getPermissionsByRoleId } from '../../services/RoleService';
import { filterSite } from '../../services/SiteService';
import { filterZone } from '../../services/ZoneService';
import CommonUtil from '../../util/CommonUtils';
import { timeZoneList } from '../../util/TimeZone';

const BindDevice = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [site, setSite] = useState([]);
  const [zone, setZone] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [timeZone, setTimeZone] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deviceType, setDeviceType] = useState('acc');
  const [loader, setLoader] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const [state, setState] = useState({
    deviceId: '',
    sn: '',
    siteId: '',
    zoneId: '',
    timeZone: '',
    registrationDevice: '0'
  });
  const [read, setRead] = useState(location.state)
  const [error, setError] = useState({
    deviceId: '',
    sn: '',
    siteId: '',
    timeZone: '',
    zoneId: '',
    registrationDevice: '0'
  });

  useEffect(() => {
    if (read === false || read === undefined || read === null) {
      navigate(-1)
    }
  }, [location.state])

  useEffect(() => {
    if (open === true) {
      const payload = {
        pageNumber: 1,
        pageSize: 0
      }
      setLoader(true);
      filterSite(payload, (response) => {
        if (response?.data?.code === 'LMSI0000') setSite(response.data.data.site);
        setLoader(false);
      })
    }
  }, [open]);

  // useEffect(() => {
  //   if (!CommonUtil.isEmpty(selectedSite)) {
  //     const payload = {
  //       pageNumber: 1,
  //       pageSize: 0,
  //       filter: { siteId: selectedSite }
  //     }
  //     filterZone(payload, (response) => {
  //       if (response?.data?.code === 'LMSI6000') {
  //         setZone(response.data.data.zones)
  //       };
  //     })
  //   }
  // }, [selectedSite])

  const handleClear = () => {
    setState({
      ...state,
      timeZone: '',
      siteId: '',
      zoneId: '',
      registrationDevice: '0'
    });
    setError({
      ...error,
      siteId: '',
      zoneId: ''
    });
    setTimeZone('')
    setZone([]);
  }
  const handleClose = () => {
    setOpen(false);
    setState({
      ...state,
      siteId: '',
      zoneId: ''
    });
    setError({
      ...error,
      siteId: '',
      zoneId: ''
    });
  }
  const [totalRecords, setTotalRecords] = useState();

  const findDevice = () => {
    if (CommonUtil.isEmptyString(state.sn)) {
      setError({
        sn: t('COMMON017'),
      });
      return;
    }
    if (!CommonUtil.isEmptyString(state.sn) && !CommonUtil.isAlphaNumericSpace(state.sn)) {
      enqueueSnackbar(t("COMMON045"), {
        variant: 'error',
      });
      return
    }
    const defaultFilterData = {
      deviceSn: '',
      deviceAlias: ''
    };
    const payload = {
      pageNumber: 1,
      pageSize: 5,
      filter: defaultFilterData
    }
    filterDevices(payload, (res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000') {
        setTotalRecords(res.data.data?.totalCount);
        if (res.data.data?.totalCount > "50") {
          enqueueSnackbar("Maximum limit reached", {
            variant: 'error',
          });
          return;
        }
        else {
          setLoader(true);
          findDeviceBySn(state.sn, (res) => {

            if (res?.data?.code === 'DMSI0000') {
              setState({
                ...state,
                deviceId: res.data.data.deviceId,
              });
              setDeviceType(res.data.data.deviceType)
              setOpen(true);
            } else {
              enqueueSnackbar(res.data.msg, {
                variant: 'error',
              });
            }
            setLoader(false);
          });
        }
      }
    });
  };
  const getTimezoneName = (value) => {
    var name = value;
    timeZoneList.map((t) => {
      if (value == t.value) {
        name = t.name;
      }
    });
    return name;
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({
        siteId: t('COMMON017'),
      });
      return;
    } else {
      setError({
        siteId: '',
      });
    }

    if (CommonUtil.isEmptyString(state.zoneId)) {
      setError({
        zoneId: t('COMMON017'),
      });
      return;
    }
    else {
      setError({
        zoneId: '',
      });
    }

    bindDevice(state, (res) => {
      if (res?.data?.code === 'DMSI0000') {
        enqueueSnackbar(t("AccDevice046"), {
          variant: 'success',
        });
        navigate(REACT_URL_DEVICE_LIST_V4);
      } else {
        enqueueSnackbar(res.data.msg, {
          variant: 'error',
        });
      }
    });
  };

  const findZone = (id) => {
    let zoneObj = { id: '', name: '' };
    zone.map((data) => {
      if (data.id === id)
        zoneObj = data;
    });
    return zoneObj;
  }

  const findSite = (id) => {
    let siteObj = { id: '', name: '' };
    site.map((data) => {
      if (data.id === id)
        siteObj = data;
    });
    return siteObj;
  }


  const zoneField = () => (
    <Autocomplete
      required
      error={error.zoneId}
      fullWidth
      disablePortal
      value={state.zoneId ? findZone(state.zoneId) : { id: '', name: '' }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="dropdown-bind-device-zone"
      getOptionLabel={(option) => option.name}
      options={zone}
      onKeyDown={handleKeyPress}
      renderInput={(params) => <CustomInput
        placeholder={t('AccDevice045')}
        handleChange={(e) => console.log()}
        height={{ xl: "47px", xs: "33.1px" }}
        padding={{ xl: "0px 20px", xs: "0px 10px" }}
        {...params} helperText={error.zoneId} error={error.zoneId} required label={t("AccDevice053")} size="small" />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            zoneId: newValue.id
          });
          setError({
            ...error,
            zoneId: ''
          });

        } else {
          setState({
            ...state,
            zoneId: ''
          });
        }
      }}
      ListboxProps={{ style: { maxHeight: '90px' } }}
    />
  )

  const siteField = (key) => (
    <Autocomplete
      fullWidth
      error={error.siteId}
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={state.siteId ? findSite(state.siteId) : { id: '', name: '' }}
      id="dropdown-bind-device-site"
      getOptionLabel={(option) => option.name}
      options={site}
      onKeyDown={handleKeyPress}
      renderInput={(params) => <CustomInput
        placeholder={t('AccDevice044')}
        handleChange={(e) => console.log()}
        height={{ xl: "47px", xs: "33.1px" }}
        padding={{ xl: "0px 20px", xs: "0px 10px" }}
        {...params} required helperText={error.siteId} error={error.siteId} label="Bind Site" size="small" />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            siteId: newValue.id,
            zoneId: ''
          });
          setError({
            ...error,
            siteId: ''
          });

          setTimeZone(newValue.address.timeZone)
          setSelectedSite(newValue.id);
          if (!CommonUtil.isEmpty(newValue.id)) {
            const payload = {
              pageNumber: 1,
              pageSize: 0,
              filter: { siteId: newValue.id }
            }
            filterZone(payload, (response) => {
              setZone([]);
              if (response?.data?.code === 'LMSI6000') {
                setZone(response.data.data.zones)
              }
            });
          }
        }else{
          setState({
            ...state,
            siteId: "",
            zoneId: ''
          });
          setZone([]);
        }
      }}
      ListboxProps={{ style: { maxHeight: '150px' } }}
    />
  )

  const handleSwitchChange = (e) => {
    setState({
      registrationDevice: e.target.checked === true ? 1 : 0
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const [perAddDevice, setAddDevice] = useState(false);

  useEffect(() => {
    setLoader(true);
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setLoader(false);
      setAddDevice(true);
    });
  }, []);

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  return (
    <>
      {loader ? <Loader open={loader} /> : <></>}
      {perAddDevice ? checkPermission("EDIT_DEVICE_LIST_DETAILS") ? (<> <TitleBar title={t('AccDevice024')} />
        <Grid container>
          <Grid item xs={12} my={1}>
            <Typography variant='title' style={{ marginBottom: '1rem', fontWeight: '450', color: '#474B4F', fontSize: '20px', opacity: '80%' }}>
              {t("AccDevice033")}
            </Typography>
          </Grid>
          <Grid
            item xs={12}
            sx={{ padding: { xl: "30px", xs: "20px" }, borderRadius: '8px', border: '1px solid #DDDDDD' }}
          >
            <Typography variant='title' style={{ fontSize: '16px', fontWeight: 450, color: '#474B4F' }}>
              {t("AccDevice034")}
            </Typography>
            <Box margin={"10px 0px 0px 15px"}>
              <Typography>{t("AccDevice035")}</Typography>
              <Typography >
                {t("AccDevice036")}
              </Typography>
              <Typography >{t("AccDevice037")}</Typography>
              <Typography >{t("AccDevice038")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} my={2}>
            <Typography variant='title' style={{ margin: '1rem 0rem', fontSize: '18px', fontWeight: '450', color: '#474B4F', opacity: '80%' }}>
              {t("AccDevice039")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            backgroundColor: '#FFFF',
            padding: { xl: "30px", xs: "20px" },
            borderRadius: '8px',
            border: '1px solid #DDDDDD'
          }}
        >
          <Grid item sm={4} paddingRight={1}>
            <CustomInput
              id="textfield-bind-device-sn"
              error={!CommonUtil.isEmptyString(error.sn)}
              fullWidth
              name='sn'
              placeholder={t('AccDevice040')}
              helperText={error.sn}
              variant='outlined'
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  findDevice();
                }
              }}
              size='small'
              height={{ xl: "64px", xs: "50px" }}
              padding={{ xl: "0px 20px", xs: "0px 10px" }}
              value={state.sn}
              handleChange={(e) => {
                setState({ sn: e.target.value });
                setError({ sn: '' });
              }}
              validation="alpha-numeric"
              inputProps={{
                maxLength: 60,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginTop: '24px',
          }}
        >
          <Button
            id="button-bind-device-add"
            onClick={() => findDevice()}
            variant='contained'
            sx={{
              width: { xl: "200px", xs: "180px" },
              height: { xl: "64px", xs: "50px" },
            }}
          >
            {t("COMMON018")}
          </Button>
        </Grid>
      </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t('AccDevice056')} </Typography></Box>)
        : <></>
      }
      <SimpleDailogBox
        open={open}
        onClose={handleClose}
        title={t('AccDevice041')}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ border: '1px solid #DDDDDD', borderRadius: '8px', display: 'flex', padding: 1.5 }}>
              {deviceType === 'cam' && <img alt='complex' src={Device} style={{ height: '60px', width: '60px' }} />}
              {deviceType === 'acc' && <AccDeviceIcon id="icon-bind-device-accdeviceicon" style={{ width: '60px' }} />}
              {deviceType === 'att' && <AttDeviceIcon id="icon-bind-device-attdeviceicon" style={{ width: '60px' }} />}
              <Box
                style={{
                  margin: '0rem 0.5rem',
                  alignSelf: 'center'
                }}
              >
                <Typography>{state.deviceModel}</Typography>
                <Typography variant='fieldLabel'>{state.sn}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography style={{ fontSize: '16px', color: '#474B4F', opacity: '80%' }}>
              {t('AccDevice042')}
            </Typography>
          </Grid>
          {!CommonUtil.isEmpty(selectedSite) &&
            <Grid item xs={12} mb={2}>
              <Typography
                style={{
                  color: '#76B745',
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
              > {timeZone !== undefined && timeZone !== '' && `This Device will Sync the same Time Zone ${getTimezoneName(timeZone)} of the Site.`}
              </Typography>
            </Grid>}
          <Grid item xs={12}>
            {siteField()}
          </Grid>
          <Grid item xs={12}>
            {zoneField()}
          </Grid>

          <Grid item xs={12} display='flex' justifyContent='center' mt={1}>
            <Button
              id="button-bind-device"
              onClick={() => handleSubmit()}
              variant='contained'
              sx={{
                width: "50%",
                height: { xl: '64px', xs: "50px" },
                marginRight: "20px"
              }}
            >
              {t('AccDevice043')}
            </Button>
            <Button
              id="button-bind-device-clear"
              onClick={() => handleClear()}
              variant='outlined'
              sx={{
                width: "50%",
                height: { xl: '64px', xs: "50px" },
              }}
            >
              {t('COMMON015')}
            </Button>
          </Grid>

        </Grid>

      </SimpleDailogBox>
    </>
  )
}

export default BindDevice
