

import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from '../../components/RighViewLayout';
import OverflowTip from '../../components/tooltip';
import { REACT_URL_ZONE_VIEW } from '../../constants/ReactEndPoints';
import { timeZoneList } from '../../services/TimeZone';
import { loadLocSiteByIds, loadLocZone } from '../../services/ZoneService';
import CommonUtil from '../../util/CommonUtils';
import { ZoneFilter } from './ZoneFilter';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';

function Zone() {

    const [zone, setZone] = useState([]);
    const { t } = useTranslation();
    const [sites, setSites] = useState([]);
    const [address, setAddress] = useState([]);
    const location = useLocation();
    const [page, setPage] = React.useState(location?.state?.pageNumber ? location?.state?.pageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize ? location?.state?.pageSize : 10);
    const [open, setOpen] = useState(false);
    const [Filtered, setFiltered] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [load, setLoad] = useState(true);
    const [perListZone, setListZone] = useState(true);
    const [perViewZone, setViewZone] = useState(true);
    const [ids, setIds] = useState([]);
    const [data, setData] = useState([])
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);
    const [filter, setFilter] = React.useState(false);


    const defaultFilter = {
        name: '',
        ids: ''
    };

    const [filterData, setFilterData] = useState({
        ...defaultFilter,
    });

    useEffect(() => {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setRoles(l);
            setListZone(l.includes("ACC_VIEW_ZONE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setViewZone(l.includes("ACC_VIEW_ZONE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        });

    }, []);

    const [roles, setRoles] = React.useState(['']);

    let navigate = useNavigate();

    const columns = [
        {
            field: 'name',
            headerName: t('ACCZONE001'),
            hideable: false,
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCZONE001')} >
                    <span>
                        {t('ACCZONE001')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'siteName',
            headerName: t('ACCDA0021'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCDA0021')} >
                    <span>
                        {t('ACCDA0021')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.siteName}
                ></OverflowTip>
            ),
        },
        {
            field: 'siteTimeZone',
            headerName: t('ACCSITE002'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCSITE002')}>
                    <span>
                        {t('ACCSITE002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.siteTimeZone}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'country',
            headerName: t('ACCDA0024'),
            hideable: false,
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCDA0024')} >
                    <span>
                        {t('ACCDA0024')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.country}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'state',
            headerName: t('ACCLANG008'),
            hideable: false,
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCLANG008')} >
                    <span>
                        {t('ACCLANG008')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.state}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'city',
            headerName: t('ACCORG020'),
            hideable: false,
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG020')} >
                    <span>
                        {t('ACCORG020')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.city}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'addressLine1',
            headerName: t('ACCLANG007'),
            hideable: false,
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCLANG007')} >
                    <span>
                        {t('ACCLANG007')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.addressLine1}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            headerName: t('ACCORG006'),
            hideable: false,
            sortable: false,
            // headerAlign: 'center',
            // align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG006')} >
                    <span>
                        {t('ACCORG006')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    {perViewZone && <GridActionsCellItem
                        icon={(<Tooltip title={t('ACCRolPerm024')}>
                            <ViewIcon id={"icon-zone-list-view"} style={{ cursor: 'pointer' }}
                                onClick={() => navigate(REACT_URL_ZONE_VIEW + `/${e.row.name}`,
                                    { state: { pageNumber: page, pageSize: rowsPerPage } })} />
                        </Tooltip>)}
                    />}
                </>
            ),
        },
    ];

    const handleFilterDataChange = () => {
        loadData(1, rowsPerPage, filterData);
        setFiltered(true);
        setLoad(false);
        setPage(1);
    };

    const loadData = (pageNumber, pageSize, filterData) => {
        setIds([]);
        loadLocZone(pageNumber, pageSize, filterData).then((zoneRes) => {
            if (zoneRes?.data?.code === 'LMSI6000') {

                setTotalRecords(zoneRes.data.data.totalCount);
                const siteIds = zoneRes.data.data.zones.map(zone => zone.siteId);
                console.log("zone ", zoneRes?.data?.data?.zones)
                var list = [];

                loadLocSiteByIds(1, 1000, { ids: siteIds }).then((siteRes) => {
                    if (siteRes?.data?.code === 'LMSI0000') {
                        (zoneRes?.data?.data?.zones).map((zoneData) => {
                            const siteData = siteRes.data.data.site.find(site => site.id === zoneData.siteId);
                            const zoneValue = {
                                id: zoneData?.id,
                                name: zoneData?.name,
                                siteName: siteData.name,
                                siteTimeZone: siteData?.address?.timeZone && getTimezoneName(siteData?.address?.timeZone),
                                country: siteData?.address?.country,
                                state: siteData?.address?.state,
                                city: siteData?.address?.city,
                                addressLine1: siteData?.address?.addressLine1,
                            }
                            list.push(zoneValue);
                        });
                        setData(list)
                    }
                });
            }
            setClearAndRefresh(false);
        });
    };

    const DEFAULT_PAGE_SIZE = 10;

    const refreshTableData = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            setFilterData(defaultFilter);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else {
            setFilterData(defaultFilter);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            setPage(1);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
    };

    const getTimezoneName = (value) => {
        var name = '';
        timeZoneList.map((t) => {
            if (value == t.value) {
                name = t.name;
            }
        });
        return name;
    };

    const actions = () => (
        <Box item display={"flex"} >
            <Tooltip title={t('ACCORG008')}>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <RefreshIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            <Tooltip title={t('ACCORG009')}>
                <Box
                    aria-label="filter"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "none", xs: "block" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
        </Box>
    );

    const FieldZoneName = (key) => (
        <CustomInput
            id={"textfiled-zone-filter-name"}
            placeholder={t('ACCZONE001')}
            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            value={filterData.name}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='name'
            //label='Zone Name'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 30,
            }}

        />
    );


    useEffect(() => {
        if (perListZone && load && !filter) {
            loadData(page, rowsPerPage, defaultFilter);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (filter && !load && reload) {
            loadData(1, rowsPerPage, filterData);
            setReload(false)
        }
    }, [filter, reload]);

    useEffect(() => {
        if (filterData.name === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
        setDisabled(event.target.value === '' ? true : false);
    };

    const clearFilter = () => {
        setFilterData({ name: '' });
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleFilterDataChange();
        }
    };

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    useEffect(() => {
        if (!open && !disabled) {
            refreshTableData();
        }
    }, [open])

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            loadData(e, rowsPerPage, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setPage(1);
            loadData(1, e, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setPage(1);
        }
    }

    return (
        <>
            {perListZone ? (<> <RightViewLayout title={t('ACCZONE002')} type='table' actions={actions} navigateBack={true} />
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldZoneName()}
                        clearFilter={clearFilter}
                        handleSubmit={handleFilterDataChange}
                        disabled={disabled}
                    />}
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        rows={data}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        // toolbar={filters}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t('ACCZONE003')} />
                </Box>

            </>)
                : (<Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>{t('ACCZONE004')}</Typography>
                </Box>)
            }
        </>
    );
}

export default Zone;
