import { Autocomplete, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ReactComponent as CancelIcon } from '../../assets/new-images/close icon.svg';
import { updateDoorAccessTime } from '../../services/DoorService';
import { getTimeZoneIntervalList } from '../../services/SchedulesService';
import CommonUtil from '../../util/CommonUtils';
import { useSnackbar } from 'notistack';
import CustomInput from '../../components/CustomInput';

const closeIconStyle = {
  height: 10,
  width: 10,
  marginTop: '12px',
  justifyContent: 'flex-end'
}

export default function EditDoorAccessTime(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);
  const [schedules, setSchedules] = React.useState([]);
  const [enablefield, setField] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  
  const [state, setState] = React.useState({
    id: props.door.doorId,
    name: props.door.doorParameters.name,
    verifyMode: props.door.doorParameters.verifyMode,
    doorSensorStatus: props.door.doorParameters.doorSensorStatus,
    lockDelay: props.door.doorParameters.lockDelay,
    sensorDelay: props.door.doorParameters.sensorDelay,
    passModeTimeSegBusinessId: props.door.doorParameters.passModeTimeSegBusinessId,
    activeTimeSegBusinessId: props.door.doorParameters.activeTimeSegBusinessId,
    forcePwd: props.door.doorParameters.forcePwd,
    supperPwd: props.door.doorParameters.supperPwd,
    doorType: props.door.doorParameters.doorType,
  });

  const [error, setError] = React.useState({
    name: false,
    verifyMode: false,
    doorSensorStatus: false,
    lockDelay: false,
    sensorDelay: false,
    passModeTimeSegBusinessId: false,
    activeTimeSegBusinessId: false,
    forcePwd: false,
    supperPwd: false,
    doorType: false,
  });

  useEffect(() => {
    setOpen(props.open);
    setState({
      id: props.door.doorId,
      name: props.door.doorParameters.name,
      verifyMode: props.door.doorParameters.verifyMode,
      doorSensorStatus: props.door.doorParameters.doorSensorStatus,
      lockDelay: props.door.doorParameters.lockDelay,
      sensorDelay: props.door.doorParameters.sensorDelay,
      passModeTimeSegBusinessId: props.door.doorParameters.passModeTimeSegBusinessId,
      activeTimeSegBusinessId: props.door.doorParameters.activeTimeSegBusinessId,
      forcePwd: props.door.doorParameters.forcePwd,
      supperPwd: props.door.doorParameters.supperPwd,
      doorType: props.door.doorParameters.doorType,
    });
  }, [props.open, props.door]);

  useEffect(() => {
    getTimeZoneIntervalList(1, 1000, '').then((res) => {
      if (state?.activeTimeSegBusinessId === '9999') {
        res.data.data.timeZone.map((data) => {
          if (data?.operationName === '24-Hours General') {
            setState({ ...state, activeTimeSegBusinessId: data.id })
          }
        })
      }
      setSchedules(res.data.data.timeZone);
    });
  }, []);


  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    if(event !==null){
      const statusValue = event.id;
      if (statusValue == 0) {
        setField(true);
      } else {
        setField(false);
      }
      setState({
        ...state,
        activeTimeSegBusinessId: statusValue,
      });
  
      setError({
        activeTimeSegBusinessId: false,
      });
    }else{
      setState({
        ...state,
        activeTimeSegBusinessId: "",
      });
    }
   
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.lockDelay)) {
      if (CommonUtil.isEmptyString(state.lockDelay)) {
        setError({ lockDelay: true });
        enqueueSnackbar(t('AccDoorAccTime023'), {variant: 'error'});
        return;
      }
    }
    if (CommonUtil.isEmptyString(state.activeTimeSegBusinessId)) {
      setError({ activeTimeSegBusinessId: true });
      enqueueSnackbar(t('AccDoorAccTime024'), {variant: 'error'});
      return;
    }
    if (!CommonUtil.isValidNumeric(state.lockDelay)) {
      setError({ lockDelay: true });
      enqueueSnackbar(t('AccDoorAccTime025'), {variant: 'error'});
      return;
    }
    if (state.lockDelay < 1 || state.lockDelay > 254) {
      setError({ lockDelay: true });
      enqueueSnackbar(t('AccDoorAccTime025'), {variant: 'error'});
      return;
    }

    if (state.forcePwd != null) {
      let forcePwd = state.forcePwd + '';
      if (!forcePwd.match('^[0-9]{0,6}$') || !CommonUtil.isValidNumeric(forcePwd)) {
        setError({ ...error, code: true });
        enqueueSnackbar(t('AccDoorAccTime026'), {variant: 'error'});
        return;
      }
    }
    if (state.supperPwd != null) {
      let supperPwd = state.supperPwd + '';
      if (!supperPwd.match('^[0-9]{0,8}$')) {
        setError({ ...error, code: true });
        enqueueSnackbar(t('AccDoorAccTime027'), {variant: 'error'});
        return;
      }
    }
    if (state.sensorDelay != null || state.sensorDelay != 0) {
      if (state.sensorDelay > 254) {
        setError({ ...error, code: true });
        enqueueSnackbar(t('AccDoorAccTime028'), {variant: 'error'});
        return;
      }
    }

    let obj = {};
    obj.parameters = state;
    updateDoorAccessTime(state.id, obj).then((res) => {
      if (res?.data?.code === 'DMSI0000') {
        enqueueSnackbar(t("AccDoorAccTime029"), {variant: 'success'});
        handleClose();
      } else {
        enqueueSnackbar(res.data.msg, {variant: 'error'});
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const findTimeSlot = (activeTimeSegBusinessId) => {
    var timeSt = { id: '',operationName:''};
    schedules.map((value) => {
        if (value.id === activeTimeSegBusinessId) {
            timeSt = value;
        }
    });
    return timeSt;
};

  const timeSlotField = () => (
    <Autocomplete
      id="dropdown-dooraccesstime-slot"
      fullWidth
      size='small'
      name='activeTimeSegBusinessId'
      onKeyDown={handleSubmit}
      value={state.activeTimeSegBusinessId ? findTimeSlot(state.activeTimeSegBusinessId) : { activeTimeSegBusinessId: '', operationName: '' }}
      options={CommonUtil.isEmpty(schedules) ? [] : schedules}
      getOptionLabel={(option) => option.operationName}
      renderInput={(params) => <CustomInput {...params}
      cursor='pointer'
        helperText={error.activeTimeSegBusinessId}
        label={t('AccDoor004')}
        placeholder={t('AccDoorAccTime016')}
        handleChange={(e) => console.log()}
        error={error.activeTimeSegBusinessId}
        height={{ xl: "47px", xs: "33.1px" }}
        padding={{ xl: "0px 20px", xs: "0px 10px" }}
      />}
      onChange={(event, newValue) => {
        handleChange(newValue)
      }}
    />
  );

  return (
    <>
      <Grid>
        {timeSlotField()}

        <Grid
          sx={{
            display: 'flex',
            justifyContent: "center",
            marginTop: { xl: "30px", xs: "30px" }
          }}>
          <Button
            id="button-confirm-modal-delete"
            variant='contained'
            onClick={() => handleSubmit()}
            sx={{
              width: { xl: '165px', xs: "150px" },
              height: { xl: '64px', xs: "50px" },
              marginRight: { xl: "30px", xs: "20px" }
            }}
          >
            {t("COMMON029")}
          </Button>
          <Button
            id="button-confirm-modal-cancel"
            onClick={() => props.onClose()}
            variant='outlined'
            sx={{
              width: { xl: '165px', xs: "150px" },
              height: { xl: '64px', xs: "50px" },
            }}
          >
            {t("COMMON009")}
          </Button>
        </Grid>
      </Grid>


    </>
  );
}
