import { Autocomplete, Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissionsByRoleId } from "../../../services/RoleService";
import api from "../../../configurations/http-common";
import CommonUtil from "../../../util/CommonUtils";
import moment from "moment-timezone";
import { getTimeZoneIntervalList, postAccessGroup } from "../../../services/AccessGroupService";
import CustomInput from "../../../components/CustomInput";
import { DatePicker } from "antd";
import SearchBar from "./SearchBar";
import AddTimeSlot from "./AddTimeSlot";
import { GetContext } from "../../../AppStore";
import { ReactComponent as AddIcon } from "../../../assets/AddIcon.svg"


const AddGroupAccess = (props) => {
  const { t } = useTranslation()
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [schedules, setSchedules] = React.useState([]);
  const screenWidth = useMediaQuery('(min-width:1540px)');
  const specialScreen = useMediaQuery('(width:1536px)');
  const { errorQuickSetupPayload, setErrorQuickSetupPayload, quickSetupPayload, setQuickSetupPayload } = GetContext();

  useEffect(() => {
    loadSchedules(0, 1000, '');
  }, []);

  useEffect(() => {
    if (props.reloadTimeSlot) {
      loadSchedules(0, 1000, '');
    }
  }, [props.reloadTimeSlot]);

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    setQuickSetupPayload({
      ...quickSetupPayload,
      accessGroup: {
        ...quickSetupPayload.accessGroup,
        name: event.target.value,
      },
    });
    setErrorQuickSetupPayload({
      ...errorQuickSetupPayload,
      accessGroup: {
        ...errorQuickSetupPayload.accessGroup,
        name: "",
      },
    })
  };

  const loadSchedules = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name).then((res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000'
        && !CommonUtil.isEmpty(res?.data?.data)
        && res?.data?.data?.timeZone instanceof Array) {
        setSchedules(res.data.data.timeZone);
      } else {
        setSchedules([]);
      }
    });
  };

  const findTimeSlotById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return '';
    }
    var timeSlot = { id: '' };
    schedules.map((s) => {
      if (s.id === id) {
        timeSlot = s;
      }
    });
    return timeSlot;
  };

  const fieldAccessGroupName = () => (
    <CustomInput
      id="textfield-addaccessgrouptime-name"
      error={errorQuickSetupPayload.accessGroup.name}
      helperText={errorQuickSetupPayload.accessGroup.name}
      name='name'
      label={"Group Access " + t('COMMON006')}
      required
      size='small'
      height={{ xl: "64px", xs: "50px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      placeholder={t('AccGroupAccessTime022')}
      value={quickSetupPayload.accessGroup.name}
      handleChange={(data) => handleChange(data)}
      inputProps={{
        maxLength: 255,
      }}
    />
  )

  const fieldTimeSlot = () => (
    <Autocomplete
      id="dropdown-addaccessgrouptime-timeslot"
      fullWidth
      value={quickSetupPayload.accessGroup.schedulerId ? findTimeSlotById(quickSetupPayload.accessGroup.schedulerId) : null}
      options={schedules ? schedules : []}
      getOptionLabel={(option) => option.operationName}
      sx={{
        "& .MuiAutocomplete-inputRoot": {
          paddingRight: "10px !important"
        }
      }}
      renderInput={(params) =>
        <CustomInput
          label={"Name of the " + t('AccTime013')}
          right={true}
          required
          size='small'
          height={{ xl: "40.7px", xs: "28.4px" }}
          padding={{ xl: "0px 20px", xs: "0px 5px" }}
          handleChange={(e) => console.log()}
          {...params}
          variant='outlined'
          placeholder={t('AccGroupAccessTime023')}
          error={errorQuickSetupPayload.accessGroup.schedulerId}
          helperText={errorQuickSetupPayload.accessGroup.schedulerId}
          cursor='pointer'
        >
          <Box sx={{ display: { xl: "block", xs: "none" }, mt: "10px", marginLeft: "20px", cursor: "pointer" }}>
            <AddIcon onClick={() => props.addTimeSlot()} />
          </Box>
          <Box sx={{ display: { xl: "none", xs: "block" }, mt: "10px", marginLeft: "20px", cursor: "pointer" }}>
            <AddIcon width={"50px"} height={"50px"} onClick={() => props.addTimeSlot()} />
          </Box>
        </CustomInput>}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setQuickSetupPayload({
            ...quickSetupPayload,
            accessGroup: {
              ...quickSetupPayload.accessGroup,
              schedulerId: newValue.id,
            },
          });
          setErrorQuickSetupPayload({
            ...errorQuickSetupPayload,
            accessGroup: {
              ...errorQuickSetupPayload.accessGroup,
              schedulerId: "",
            }
          })
        } else {
          setQuickSetupPayload({
            ...quickSetupPayload,
            accessGroup: {
              ...quickSetupPayload.accessGroup,
              schedulerId: "",
            }
          });
          setErrorQuickSetupPayload({
            ...errorQuickSetupPayload,
            accessGroup: {
              ...errorQuickSetupPayload.accessGroup,
              schedulerId: "",
            }
          })
        }
      }}
    />
  );

  const errorStyle = {
    color: "#d32f2f",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
    marginTop: "4px",
    marginLeft: "14px"
  }

  const disabledEndDate = (current) => {
    const currentv1 = new Date(current);
    const startTime1 = new Date(quickSetupPayload.accessGroup.startTime);
    startTime1.setDate(startTime1.getDate() - 1);
    return current && (currentv1 < startTime1);
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const fieldStartTime = () => (
    <>
      <Typography variant='inputFieldLabel' >
        {t('AccGroupAccessTime004')} <span style={{ color: 'red' }}>*</span>
      </Typography>
      <DatePicker
        showTime
        placeholder={t('AccGroupAccessTime024')}
        value={quickSetupPayload.accessGroup.startTime}
        status={errorQuickSetupPayload.accessGroup.startTime ? "error" : ""}
        label={t('AccGroupAccessTime004')}
        onChange={(startTime) => {
          setQuickSetupPayload({
            ...quickSetupPayload,
            accessGroup: {
              ...quickSetupPayload.accessGroup,
              startTime: startTime,
            },
          });
          setErrorQuickSetupPayload({
            ...errorQuickSetupPayload,
            accessGroup: {
              ...errorQuickSetupPayload.accessGroup,
              startTime: "",
            },
          });
        }}
        // format={yyyy-MM-dd'T'HH:mm:ssXXX"}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabledDate={disabledStartDate}
        style={{
          width: '100%',
          height: screenWidth ? "64px" : specialScreen ? "64px" : "50px",
          marginTop: "10px",
          cursor: "pointer",
        }}
      
      />
      {errorQuickSetupPayload.accessGroup.startTime !== "" && <Typography sx={{ ...errorStyle }} >
        {errorQuickSetupPayload.accessGroup.startTime}
      </Typography>}
    </ >
  );

  const fieldEndDate = () => (
    <>
      <Typography variant='inputFieldLabel' >
        {t('AccGroupAccessTime005')} <span style={{ color: 'red' }}>*</span>
      </Typography>
      <DatePicker
        showTime
        placeholder={t('AccGroupAccessTime025')}
        value={quickSetupPayload.accessGroup.endTime}
        label={t('AccGroupAccessTime005')}
        onChange={(endTime) => {
          setQuickSetupPayload({
            ...quickSetupPayload,
            accessGroup: {
              ...quickSetupPayload.accessGroup,
              endTime: endTime,
            },
          });
          setErrorQuickSetupPayload({
            ...errorQuickSetupPayload,
            accessGroup: {
              ...errorQuickSetupPayload.accessGroup,
              endTime: "",
            },
          });
        }}
        getPopupContainer={(trigger) => trigger.parentNode}
        status={errorQuickSetupPayload.accessGroup.endTime ? "error" : ""}
        disabledDate={disabledEndDate}
        style={{
          width: '100%',
          marginTop: "10px",
          height: screenWidth ? "64px" : specialScreen ? "64px" : "50px",
          cursor: "pointer",
        }}
      />
      {errorQuickSetupPayload.accessGroup.endTime !== "" && <Typography sx={errorStyle} >
        {errorQuickSetupPayload.accessGroup.endTime}
      </Typography>}
    </>
  )
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          border: '1px solid #DDDDDD',
          width: '100%',
          borderRadius: { xl: '8px', xs: "6px" },
          padding: { xl: '40px 30px 50px 30px', xs: '30px 25px 40px 25px' },

          font: "normal normal medium 16px/19px Roboto",
          '&::after': {
            content: '"Access Time"',
            position: 'absolute',
            top: '-13px',
            left: '30px',
            backgroundColor: '#fff',
            padding: '0 5px',
            whiteSpace: 'nowrap',
            color: "rgba(71, 75, 79, 0.8)",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "Roboto"
          },
          '&:hover': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          }
        }}
        id="add-access-group-page"
      >

        <Grid container>
          <Grid item xs={12} md={6} sx={{ pr: { xl: "15px", md: "10px" } }}>
            {fieldAccessGroupName()}
          </Grid>
          <Grid item xs={12} md={6} sx={{ pl: { xl: "15px", md: "10px" } }} >
            {fieldTimeSlot()}
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: { xl: "15px", md: "10px" }, position:"relative" }}>
            {fieldStartTime()}
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: { xl: "0px", md: "0px", xs: "20px" }, pl: { xl: "15px", md: "10px" } }} >
            {fieldEndDate()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddGroupAccess;
