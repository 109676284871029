import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RightViewLayout from '../../components/RighViewLayout'
import { getSiteById } from '../../services/SiteService'
import { getZoneById } from '../../services/ZoneService';
import { ReactComponent as AccDeviceIcon } from '../../assets/DeviceIcon/accDeviceICon.svg';
import { ReactComponent as AttDeviceIcon } from '../../assets/DeviceIcon/attDeviceIcon.svg';
import Device from '../../assets/DeviceIcon/camIcon.png';
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import { deletePersonFromDevice, deletePersonsCredentials, deletetCredentialByEmpCodeAndCredTypeId, filterDevices, findDeviceBySn, getDeviceSpecs, personsFromDevice } from '../../services/DeviceService';
import { ReactComponent as DeleteOne } from "../../assets/new-images/Delete Icon.svg";
import DataTable from '../../components/layout-components/DataTable'
import CredentialCountBox from './Credential/CredentialCountBox'
import CredentialCount from './Credential/CredentialCount'
import SelectUserRole from './SelectUserRole'
import CustomDialogBox from '../../components/CustomDialogBox'
import { useSnackbar } from 'notistack'
import { REACT_URL_DEVICE_LIST_V4, REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints'
import CommonUtil from '../../util/CommonUtils'
import api from '../../configurations/http-common'
import { resolveOnChange } from 'antd/lib/input/Input'
import axios from 'axios'
import PageNotFound from '../../components/PageNotFound'
import { getPermissionsByRoleId } from '../../services/RoleService';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';
import { PersonInDeviceImage } from './DeviceImage'


const PersonInDevice = () => {
  const [siteName, setSiteName] = useState('');
  const [zoneName, setZoneName] = useState('');
  const location = useLocation();
  const { t } = useTranslation();
  const [deviceSupports, setDeviceSupports] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDelete, setOpenDelete] = useState(false)
  const [emp, setEmp] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { sn } = useParams();
  const [viewErrorPage, setViewErrorPage] = useState(false)
  const [payload, setPayload] = useState("");
  const [loading, isLoading] = useState(false)
  const [personInDevice, setPersonInDevice] = React.useState(false);
  const [permissions, setPermissions] = useState([]);
  const [ids, setIds] = useState([]);

  const [deviceType, setDeviceType] = useState(!CommonUtil.isEmpty(location.state) ? location.state.data.deviceType : "acc")
  const [enrollSupport, setEnrollSupport] = useState('');
  const [cardEnrollSupport, setCardEnrollSupport] = useState('');

  const loadDeviceBySN = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 10,
      filter: {
        deviceSn: sn
      }
    }
    filterDevices(payload, (res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data?.devices)) {
        if (res.data.data.devices[0].parameters.multiBioEnrollSupport == undefined) {
          isLoading(true)
        } else {
          setPayload(res.data.data.devices[0]);
          setSupport(res.data.data.devices[0].parameters);
        }

      } else {
        setViewErrorPage(true);
      }
    });
  };

  const setSupport = (param) => {

    if (param) {
      setEnrollSupport(param.multiBioEnrollSupport);
      setCardEnrollSupport(param.cardEnrollSupport);
    }
  }







  // This is for getting zone name and site name using id.
  useEffect(() => {
    if (!CommonUtil.isEmpty(payload)) {
      if (CommonUtil.isEmpty(sn)) {
        navigate({
          pathname: REACT_URL_PAGE_NOT_FOUND,
        });
      }

      getSiteById(payload.siteId, (response) => {
        if (response?.data?.code === 'LMSI6000') setSiteName(response.data.data.name);
      });

      getZoneById(payload.zoneId, (response) => {
        if (response?.data?.code === 'LMSI6000') setZoneName(response.data.data.name);
      })

      getDeviceSpecs(payload.id, sn, (res) => {
        if (res?.data?.code === 'DMSI0000') {
          setDeviceSupports(res.data.data);
        }
      });
    }

  }, [payload])

  useEffect(() => {
    if (CommonUtil.isEmpty(sn)) {
      navigate({
        pathname: REACT_URL_PAGE_NOT_FOUND,
      });
    }
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setPersonInDevice(true);
    });
  }, []);

  useEffect(() => {
    if (checkPermission("EDIT_DEVICE_LIST_DETAILS")) {
      loadDeviceBySN();
    }
  }, [permissions])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  useEffect(() => {
    if (payload?.id)
      loadCredentials(page, rowsPerPage);
  }, [page, rowsPerPage, payload])


  const loadCredentials = (newPage, rowsPerPage) => {
    setIds([]);
    personsFromDevice(payload.id, newPage, rowsPerPage, (data) => {
      if (data?.data?.code === 'CMSI0000') {
        setTotalRecords(data.data.data.totalCount);
        setData(data.data.data.employees);
      } else {
        setTotalRecords(0);
        setData([]);
      }
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: t('ACCORG002'),
      flex: 1.2,
      tooltip: t('ACCORG002'),
      renderCell: (e) => (
        <>
          <Avatar sx={{ bgcolor: '#C0C0C0', height: '40px', width: '40px', marginRight: '10px' }} alt={e.row.employeeId} src={e.row.profilePhotoURL}></Avatar>
          <Typography>{e.row.lastName ? e.row.firstName + ' ' + e.row.lastName : e.row.firstName}</Typography>
        </>
      ),
    },

    { field: 'employeeCode', headerName: t('ACCORG003'), tooltip: t('ACCORG003'), flex: 1 },

    {
      field: t('ACCRolPerm059'),
      title: 'User Role in this Device',
      flex: 1,
      renderCell: (e) => (
        <SelectUserRole
          value={e.row}
          devId={!CommonUtil.isEmpty(payload) && payload.id}
          employeeId={e.row.employeeId}
        />
      )
    },
    {
      field: 'credential', headerName: t('AccDevice060'), tooltip: t('AccDevice060'), flex: 3,
      renderCell: (e) => (
        <>
          <CredentialCount
            deviceDetails={!CommonUtil.isEmpty(payload) && payload}
            credential={e.row}
            enrollSupport={enrollSupport}
            cardEnrollSupport={cardEnrollSupport}
            // deleteIndividualCredential={deleteIndividualCredential}
            reload={() => loadCredentials(page, rowsPerPage)}
            devId={!CommonUtil.isEmpty(payload) && payload.id}
            deviceSupports={deviceSupports}
          />
        </>
      )
    },


    // {
    //   field: 'actions', headerName: 'Actions', type: 'actions', flex: 1,
    //   renderCell: (e) => (
    //     <>
    //       <Tooltip title={"Delete"}>< DeleteOne width={'12px'}
    //         onClick={() => { setEmp(e.row); setOpenDelete(!openDelete); }}
    //       /></Tooltip>
    //     </>
    //   )
    // }
  ];


  // const deleteIndividualCredential = (value) => {
  //   if (value.value !== 0)
  //     deletetCredentialByEmpCodeAndCredTypeId(value.data.employeeCode, value.credentialType, (res) => {
  //       if (res.data.code === 'CMSI0012') {
  //         enqueueSnackbar(res.data.message, {
  //           variant: 'success',
  //         });
  //         loadCredentials(page, rowsPerPage);
  //       } else {
  //         enqueueSnackbar(res.data.message, {
  //           variant: 'error',
  //         });
  //       }
  //     });
  //   else
  //     enqueueSnackbar("There is no Credential mapped", {
  //       variant: 'error',
  //     });
  // }

  const handleDeletePersonsCredential = () => {
    const payload1 = {
      deviceId: payload.id,
      empId: emp.employeeId,
      firstName: emp.firstName,
      lastName: emp.lastName,
      ip: payload.ip
    }
    deletePersonFromDevice(payload1, (data) => {
      if (data?.data?.code === 'DMSI00708') {
        enqueueSnackbar(data.data.msg, {
          variant: 'success',
        });
        setOpenDelete(false)
        loadCredentials(page, rowsPerPage);
      } else {
        enqueueSnackbar(data.data.msg, {
          variant: 'error',
        });
        setOpenDelete(false)
      }
    });
  }

  const handleClose = () => {
    setOpenDelete(false);
  }

  const deviceDetail = () => {
    return (
      <Box sx={{
        border: "1px solid #DDDDDD",
        width: "100%",
        borderRadius: '8px',
        padding: { xl: "30px", xs: "15px 26px" },
        marginTop: { xl: "30px", xs: "15px" },
        marginBottom: { xl: "30px", xs: "15px" },
        display: "flex"
      }}
      ><Box alignSelf={"center"}>
          {PersonInDeviceImage[location.state.data.deviceModel] ? PersonInDeviceImage[location.state.data.deviceModel] : <AccDeviceIcon width={'80px'} height={'80px'} />}
        </Box>
        <Box
          style={{
            margin: '0px 20px',
            alignSelf: 'center'
          }}
        >
          <Typography variant='fieldValue' >{!CommonUtil.isEmpty(location.state.data.deviceModel) ? location.state.data.deviceModel : "-"}</Typography>
          <br /><Typography variant='fieldLabel'>{"Site: " + siteName}</Typography>
          <br />
          <Typography variant='fieldLabel'>{"Zone: " + zoneName}</Typography>
        </Box>
      </Box>
    )
  }
  const DEFAULT_PAGE_SIZE = 10;
  const handleRefresh = () => {
    if (page === 1) {
      loadCredentials(1, DEFAULT_PAGE_SIZE);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else {
      setPage(1);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
  }

  const onSelectChecxBoxChange = (id) => {
    setIds(id);
  };
  return (
    <>

      {viewErrorPage ? <PageNotFound></PageNotFound> : personInDevice ? checkPermission("EDIT_DEVICE_LIST_DETAILS") ? <><RightViewLayout
        navigateBack={false}
        title={t('AccDevice058')}
        type='table'
      />
        {deviceDetail()}
        <Box display='flex' justifyContent={'space-between'} alignItems='center' width={'100%'}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: { xl: "20px", xs: "10px" }
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                textAlign: 'left',
                font: "normal normal medium 20px/24px Roboto",
                letterSpacing: "0px",
                color: " #474B4F",
                textTransform: "capitalize",
                opacity: 0.8,
                alignSelf: "center",
                marginRight: "10px"

              }}
            >
              {t('AccDevice059')}
            </Typography>
            <Tooltip
              placement='right'
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#fff !important',
                    border: '1px solid #76B745',
                    color: '#474B4F',
                    opacity: 0.8
                  }
                },
              }}
              title={t("AccDevice064")}
            >
              <HelpOutlineIcon
                style={{
                  color: '#76B745',
                  height: 30,
                  width: 30,
                  cursor: 'pointer'
                }} />
            </Tooltip>
          </Box>

          <Tooltip title={t('ACCORG008')}>
            <Box
              aria-label="refresh"
              onClick={() => handleRefresh()}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"refresh-Icon"}
            >
              <Refresh style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="refresh"
              onClick={() => handleRefresh()}
              sx={{ display: { xl: "none", xs: "block" } }}
            >
              <Refresh width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip>
        </Box>
        <DataTable
          onSelection={(e) => onSelectChecxBoxChange(e)}
          page={page - 1}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          rows={data}
          columns={columns}
          getRowId={(row) => row.employeeId}
          checkboxSelection={false}
          onPageChange={(e) => setPage(e + 1)}
          rowSelectionModel={ids}
          onPageSizeChange={(e) => setRowsPerPage(e)}
          loaderData={loading}
          noRecordText={t('AccDevice061')}
        /></> : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t("AccDevice062")}</Typography>
        </Box>) : <></>}
      <CustomDialogBox
        id="deleteDevcie"
        open={openDelete}
        customWidth={'420px'}
        title={"Are you sure!"}
        message={"Do you want to delete the Person Credentials?"}
        saveButtonName={"Delete"}
        cancelButtonName={"Cancel"}
        onClose={handleClose}
        handleSubmit={handleDeletePersonsCredential}
      />

    </>
  )
}

export default PersonInDevice
