
import React, { useState } from "react";
import { styled, TextField, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const MuiTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: '#76B745',
    },
    secondary: {
      main: "#f50057",
    },
  },
});


const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  color:'#76B745',
  border:'1px solid #76B745',
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: '#76B745',
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  color:'#0000008a',
  border:'1px solid #76B745',
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: '#76B745',
    color: "#FFFF",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize:'13px',
        padding:'10px'
      }
    }
  },
  cssLabel: {
    color: '#0000008a'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#76B745 !important',
    }
  },
  cssFocused: {
    color: '#0000008a !important'
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important'
  },
  dialogbox: {
    "& .css-hlj6pa-MuiDialogActions-root": {
      marginLeft: '290px !important',
    },
    "& .css-ypiqx9-MuiDialogContent-root": {
      padding: '0px !important',
    },
  },

}));


export default function CustomDateTimePicker(props) {
  const classes = useStyles();
  const [dateOpen, setDateOpen] = useState(false);
  const [scheduledStartDate, setScheduledStartDate] = React.useState(
    new Date()
  );

  const handleDate = (e) => {
    setScheduledStartDate(e);
    props.SelectedDate(e);
  };

  return (

      <ThemeProvider theme={MuiTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateTimePicker
            disabled={props.disabled}
            hideTabs={false}
            components={{
              LeftArrowIcon: CustomIconLeft,
              RightArrowIcon: CustomIconRight,
            }}
            disablePast= {props.disablePast}
            disableFuture={props.disableFuture}
            onChange={props.onChange}
            onChangeText={props.onChangeText}
            inputFormat="dd-MM-yyyy HH:mm"
            value={props.value}
            minDateTime={props.minDateTime}
            type="datetime-local"
            className={classes.root}
            InputProps={{
              classes:
              {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            renderInput={(params) => (
              <TextField style={{width:'-webkit-fill-available', }}
                {...params}
                 size='small'
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}  
              />
            )}
          />
        </LocalizationProvider>
      </ThemeProvider>
  );
}
