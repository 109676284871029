import DataTable from '../../components/layout-components/DataTable';
import { Autocomplete, Grid, Icon, IconButton, InputLabel, TextField, Tooltip, Typography } from '@mui/material';
import TitleBar from '../../components/TitleBar';
import { Box } from '@mui/system';
import { GridFilterAltIcon } from '@mui/x-data-grid';
import { Avatar } from '@mui/material';
import OverFlowText from '../../components/OverFlowText';
import OverflowTip from '../../components/tooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import RefreshIcon from '@mui/icons-material/Refresh';
import RightViewLayout from '../../components/RighViewLayout';
import React, { useDebugValue, useEffect, useState } from "react";
import { loadEvents } from "../../services/EventService";
import CommonUtil from '../../util/CommonUtils';
import { EventsFilter } from './EventsFilter';
import { loadOrgPersonsById } from "../../services/PersonService";
import { DatePicker } from 'antd';
import moment from 'moment';
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { allDevices } from '../../services/DeviceService';
import ExportIcon from './EventExport';
import { downloadExcel, generatedReports } from '../../services/GenratedReports';
import { ReactComponent as ViewIcon } from "../../assets/download.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon} from '../../assets/Refresh.svg';


function GeneratedReports() {


    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(1);
    const { t } = useTranslation();
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
    const [Filtered, setFiltered] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [filter, setFilter] = useState(false);

    const defaultFilter = {
        startDateTime: '',
        endDateTime: '',
        deviceName:'',
        sn:'',
        eventType:''
    };

    const [filterData, setFilterData] = useState({
        ...defaultFilter,
    });

    const [eventDateData, setEventDateData] = useState({
        ...defaultFilter,
    });


    useEffect(() => {
        // async function fetchData() {
          loadData(page, rowsPerPage, filterData);
        
        // }
        // fetchData();
    }, []);


    const loadData = (pageNumber, pageSize, filterData) => {
      setData([]);
      const payload = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filterData,
      }
      generatedReports(payload, (res) => {
        if (!CommonUtil.isEmpty(res) && res?.data?.code === 'CASI301') {
          setTotalRecords(res.data.data.totalCount);
          setData(res.data.data.Reports);
        } else {
          setTotalRecords(0);
          console.log();
          setData([]);
        }
      });
    };

    const columns = [
      { field: 'type', headerName: t('ACCEVENT017'), flex: 1 },
      { field: 'requestedAt', headerName: t('ACCEVENT018'),sortable: false,
      headerAlign: 'center',
      align: 'center', flex: 1, valueGetter: (e) => e.row.requestedAt ? getDateAndTime(e.row.requestedAt) : '' },
      {
        field: 'numberOfRecords', 
        headerName: t('ACCEVENT019'),
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 1
      },
      {
        field: 'status',
        headerName:t('AccDevice051'),
        flex: 1,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let spanText = '';
          let spanColor = '';
          let spanBgColor = '';
  
          if (params.row.status === 'Completed') {
            spanText = t('ACCEVENT020');
            spanColor = '#76B745';
            spanBgColor = '#76B74526';
          } else if (params.row.status === 'In_Progress') {
            spanText = t('ACCEVENT030');
            spanColor = '#EDBD37';
            spanBgColor = '#EDBD3726';
          } else if (params.row.status === 'FAILED') {
            spanText =t('ACCEVENT031');
            spanColor = '#FF0000';
            spanBgColor = '#FF000026';
          }
  
          return (
            <span style={{ color: spanColor, backgroundColor: spanBgColor, opacity: 1 }}>
              {spanText}
            </span>
          );
        },
      },
      {
        headerName:t('COMMON001'),
        headerClassName: 'no-sort-filter',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => (
          params.row.status === 'Completed' && (
          
              <Tooltip title={t('ACCEVENT032')} arrow>
                <ViewIcon onClick={() => handleActions(0, params)} style={{ alignSelf: 'center', paddingTop: '0px',cursor:'pointer' }} />
              </Tooltip>
            )
        ),
      },
    ];

    const getDateAndTime = (date) => {
      var dt = new Date(date.replace(' ', 'T'));
      return CommonUtil.getLocalDate(dt);
    }

    const handleActions = (action, params) => {
      const payload = {
        objectKey: params.row.objectKey,
      }
      downloadExcel(payload, (res) => {
        if (!CommonUtil.isEmpty(res) && res?.data?.code === 'STSI0002') {
          window.open(res.data.data.preSignedUrl, '_blank');
        }
      });
    }

    const resetFilterData = () => {
        setFilterData({ ...defaultFilter });
        setEventDateData({ ...defaultFilter });
        setPage(1);
        loadData(1, rowsPerPage, defaultFilter);
        setFiltered(false);
        setDisabled(true);
    };

    const handleFilterDataChange = (filter) => {
        setFilterData({
            ...filter,
        });
        loadData(1, rowsPerPage, filter);
        setFiltered(true);
        // setOpen(false);
    };

    const DEFAULT_PAGE_SIZE = 10;

    const refreshTableData = () => {
        setFilterData({
            ...defaultFilter,
        });
        setPage(1);
        loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    };

    const rows = [
        {
            id: 1, personId: 'ID00123', personName: 'dummy',
            deviceAlias: 'Device 01', sn: '191.168.11.13',
            eventTime: 'Time 0001',
            eventAddress: 'Event address 0005421', eventName: 'Event name 01', epName: 'EP name 001', verificationMode: 'Password'
        },
    ];


    const actions = () => (
        <>
            <Box item>
                <Tooltip title={t('COMMON002')}>
      
                  <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={0}
                        sx={{ display: { xl: "block", xs: "none" } }}
                        id={"icon-generatereports-refresh"}
                    >
                        <RefreshIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={0}
                        sx={{ display: { xl: "none", xs: "block" } }}
                        id={"icon-generatereports-refresh"}
                    >
                        <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                {/* <Tooltip title='Filter'>
                    <IconButton aria-label="filter" onClick={() => {
                        setOpen(!open);
                        setDisabled(true);
                        setEventDateData({
                            ...defaultFilter,
                        });
                    }}>
                        <GridFilterAltIcon fontSize="inherit"  style={{cursor: 'pointer' }}/>
                    </IconButton>
                </Tooltip> */}
                {/* <IconButton arrow aria-label="add" style={{'background':'#76B745','color':'white'}}>
                    <AddIcon fontSize="inherit" />
                </IconButton> */}
            </Box>
        </>

    );

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    const FieldStartDate = (key) => (
        <DatePicker
            showTime
            placeholder={t('ACCEVENT005')}
            value={eventDateData.startDateTime}
            onChange={(startDateTime) => {
                setEventDateData({ ...eventDateData, startDateTime: startDateTime });
                handleDisable();
                setFilterData({ ...filterData, startDateTime: startDateTime.format('YYYY-MM-DD HH:mm:ss') });

            }}
            allowClear={false}
            minDateTime={eventDateData.startDateTime}
            disabledDate={disabledDate}
            style={{height: '40px', borderRadius: '5px'}}
        />
    );

    const FieldSerialNumber = (key) => (
        <Grid item xs={2} sm={3}>
        <CustomInput
        id="textfield-generatereports-sn"
          placeholder={t('AccDevice013')}
          style={{ margin: '10px' }}
          value={filterData.sn}
          size='small'
          fullWidth
          name='sn'
          variant='outlined'
          handleChange={handleChange}
          inputProps={{
            maxLength: 30,
          }}
        />
        </Grid>
      );

      const handleChange = (event) => {
        setFilter(false);
        const name = event.target.name;
        setFilterData({
          ...filterData,
          [name]: event.target.value
        });
          setDisabled(false);
      };

    const handleDisable = () => {
        if (eventDateData.startDateTime != null || eventDateData.endDateTime != null) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const FieldEndDate = (key) => (
        <DatePicker
            showTime
            placeholder={t('ACCEVENT006')}
            value={eventDateData.endDateTime}
            onChange={(endDateTime) => {
                setEventDateData({ ...eventDateData, endDateTime: endDateTime });
                handleDisable();
                setFilterData({ ...filterData, endDateTime: endDateTime.format('YYYY-MM-DD HH:mm:ss') });
                
            }}
            allowClear={false}
            minDateTime={eventDateData.startDateTime}
            disabledDate={disabledEndDate}
            style={{height: '40px', borderRadius: '5px' }}
        />
    );

    const getDeviceName = (data) => {

    }; 


    const handleSubmit = () => {
        if (eventDateData.startDateTime === null && eventDateData.endDateTime === null) {
            // toast.error(t('COMMON015'));
            return;
        }
        
        var startDateTime = '';
        var endDateTime = '';
        var startDate = eventDateData.startDateTime;
        var endDate = eventDateData.endDateTime;
        var filterStartDate = new Date(startDate === null ? '' : startDate);
        var filterEndDate = new Date(endDate === null ? '' : endDate);
        var dto = '';

        if (eventDateData.endDateTime !== null) {
            endDateTime =
                filterEndDate.getFullYear() +
                formatDayAndMonth(filterEndDate.getMonth() + 1) +
                formatDayAndMonth(filterEndDate.getDate()) +
                ' ' +
                filterEndDate.getHours() +
                ':' +
                filterEndDate.getMinutes() +
                ':' +
                filterEndDate.getSeconds();

            setFilterData({
                startDateTime: '',
                endDateTime: endDateTime,
            });
            
        }

        if (eventDateData.startDateTime !== null && startDate!='') {

            startDateTime =
                filterStartDate.getFullYear() +
                formatDayAndMonth(filterStartDate.getMonth() + 1) +
                formatDayAndMonth(filterStartDate.getDate()) +
                ' ' +
                filterStartDate.getHours() +
                ':' +
                filterStartDate.getMinutes() +
                ':' +
                filterStartDate.getSeconds();


            setFilterData({
                startDateTime: startDateTime,
                endDateTime: '',
            });

        }
        setPage(1);
        loadData(1, rowsPerPage, filterData);
        setFiltered(true);
    }

    const disabledEndDate = (current) => {
        // Can not select days before today and today
        return current && (current < eventDateData.startDateTime || current > moment().endOf('day'));
    };
    const CustomTitleBar = (props) => {
      return (
        <Box display='flex' alignItems='center'paddingBottom={2}>
          <Box item flexGrow={1}>
          <Typography sx={{ 
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '21px',
          color: '#474B4F',
          fontFamily:'Roboto'
        }}>
          <b>Note:</b> {props.title}
        </Typography>
      </Box>
      <Box item>{props.children}</Box>
    </Box>
      )
    }

    const formatDayAndMonth = (numValue) => {
        if (numValue <= 0) {
            return '-' + (numValue + 12);
        }

        if (numValue < 10) {
            return '-0' + numValue;
        } else {
            return '-' + numValue;
        }
    };

    

    return (
        <>
            <RightViewLayout title={t('ACCEVENT033')} type='table' actions={actions} navigateBack={false}/>
            <Box sx={{ marginTop:{xl:"30px",xs:"15px"},width:"100%"}}>
            <CustomTitleBar title={t('ACCEVENT034')}/>
                <DataTable
                    page={page - 1}
                    totalRecords={totalRecords}
                    rowsPerPage={rowsPerPage}
                    columns={columns}
                    getRowId={(row) => row.id}
                    rows={data}
                    // toolbar={filters}
                    checkboxSelection={false}
                    onSelection={(e) => console.log()}
                    onPageChange={(e) => {
                        const CurrentPage = CommonUtil.getPageNumber(e);
                        setPage(CurrentPage);
                        if (Filtered) {
                            loadData(CurrentPage, rowsPerPage, filterData);
                        } else {
                            loadData(CurrentPage, rowsPerPage, defaultFilter);
                        }
                    }}
                    onPageSizeChange={(e) => {
                        setFilterData({ ...filterData });
                        setRowsPerPage(e);
                        setPage(1);
                        loadData(1, e, filterData);
                    }}
                    noRecordText={t('ACCEVENT035')} />
                    </Box>
        </>
    );
}

export default GeneratedReports;