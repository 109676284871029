import { Close } from '@mui/icons-material';
import { Autocomplete, Button, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import CustomInput from '../../components/CustomInput';
import { doorOperation, updateDoor, updateDoorTimeSlot } from '../../services/DoorService';
import { getTimeZoneIntervalList } from '../../services/SchedulesService';
import CommonUtil from '../../util/CommonUtils';
import { useTranslation } from 'react-i18next';

const DoorAccessTimeSlot = (props) => {
    const { t } = useTranslation()
    const [timeSlot, setTimeSlot] = useState([]);
    const [timeSlotName, setTimeSlotName] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        timeslotId: '',
        passModeTimeSegBusinessId: '',
        activeTimeSegBusinessId: props.passModeTimeSegBusinessId
    });

    const [error, setError] = useState({
        timeSlotId: ''
    });

    var defaultTimeSlotNone = { id: '0', operationName: 'None' };
    var defaultTimeSlotInfiniteOpen = { id: '1', operationName: 'Switch The Door Keep Open Status Immediately' };
    useEffect(() => {
        getTimeZoneIntervalList(1, 0, '').then((res) => {
            if (res?.data?.code === 'DMSI0000' && res?.data?.data?.timeZone instanceof Array) {
                if (state.activeTimeSegBusinessId === '9999') {
                    res.data.data.timeZone.map((data) => {
                        if (data?.operationName === '24-Hours General') {
                            setState({ ...state, activeTimeSegBusinessId: data.id })

                        }
                    })
                }
                let timeSlot = [];
                timeSlot.push(defaultTimeSlotNone);
                timeSlot.push(defaultTimeSlotInfiniteOpen);
                res.data.data.timeZone.map((value) => {
                    timeSlot.push(value);
                });
                setTimeSlot(timeSlot);
            } else {
                let timeSlot = [];
                timeSlot.push(defaultTimeSlotInfiniteOpen);
                setTimeSlot(timeSlot);
            }
        });
    }, []);

    const findTimeSlot = (activeTimeSegBusinessId) => {
        var timeSt = { id: '', operationName: '' };
        timeSlot.map((value) => {
            if (value.id === activeTimeSegBusinessId) {
                timeSt = value;
            }
        });
        return timeSt;
    };

    const handleSubmit = () => {
        if (state.activeTimeSegBusinessId === '1') {
            let payload = {
                operator: 0,
                type: 0,
                triggerNum: props.doorNo,
                delayTime: '255',
            };

            doorOperation(props.deviceId, props.doorName, payload).then((res) => {
                props.onClose(false);
                if (res?.data?.code === 'DMSI0000') {
                    let payloadObj = {
                        parameters: {
                            passModeTimeSegBusinessId: 1,
                            name: props.doorName
                        }
                    };
                    updateDoorTimeSlot(props.doorId, timeSlotName, payloadObj, (res) => {
                        console.log(res?.data?.code)
                    })
                    enqueueSnackbar(t("AccDoorAccTime013"), { variant: 'success' });
                } else {
                    enqueueSnackbar(res.data.msg, { variant: 'error' });
                }
            });
        } else if (state.activeTimeSegBusinessId === '0') {
            let payload = {
                operator: 0,
                type: 0,
                triggerNum: props.doorNo,
                delayTime: '0',
            };

            doorOperation(props.deviceId, props.doorName, payload).then((res) => {
                props.onClose(false);
                if (res?.data?.code === 'DMSI0000') {
                    let payloadObj = {
                        parameters: {
                            passModeTimeSegBusinessId: 0,
                            name: props.doorName
                        }
                    };
                    updateDoorTimeSlot(props.doorId, timeSlotName, payloadObj, (res) => {
                        console.log(res?.data?.code)
                    })
                    enqueueSnackbar(t("AccDoorAccTime034"), { variant: 'success' });

                } else {
                    enqueueSnackbar(res.data.msg, { variant: 'error' });
                }
            });
        }
        else {
            let payloadObj = {
                parameters: {
                    passModeTimeSegBusinessId: state.activeTimeSegBusinessId,
                    name: props.doorName
                }
            };
            updateDoorTimeSlot(props.doorId, timeSlotName, payloadObj, (res) => {
                if (res?.data?.code === 'DMSI0000') {
                    enqueueSnackbar(t("AccDoorAccTime015"), {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar(res.data.msg, {
                        variant: 'error',
                    });
                }
                props.onClose()
            });
        }
        props.setPassModeTime(state.activeTimeSegBusinessId);
    }

    const TimeSlotAutocomplete = () => {
        return (
            <Autocomplete
                id="dropdown-dooraccesstime-slot"
                sx={{ '& .MuiAutocomplete-input': { cursor: 'pointer' } }}
                fullWidth
                size='small'
                onKeyDown={handleSubmit}
                value={state.activeTimeSegBusinessId ? findTimeSlot(state.activeTimeSegBusinessId) : { activeTimeSegBusinessId: '', operationName: '' }}
                options={CommonUtil.isEmpty(timeSlot) ? [] : timeSlot}
                getOptionLabel={(option) => option.operationName}
                renderInput={(params) => <CustomInput {...params}
                    helperText={error.activeTimeSegBusinessId}
                    label={t("COMMON048")}
                    placeholder={t('AccDoorAccTime016')}
                    handleChange={(e) => console.log()}
                    error={error.activeTimeSegBusinessId}
                    height={{ xl: "47px", xs: "33.1px" }}
                    padding={{ xl: "0px 20px", xs: "0px 10px" }}
                />}
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        setTimeSlotName(newValue.operationName)
                        setState({ ...state, activeTimeSegBusinessId: newValue.id });
                        setError({ ...error, activeTimeSegBusinessId: '' });
                    } else {
                        setState({ ...state, activeTimeSegBusinessId: "" });
                    }
                }}
            />
        )
    }

    const infoStyle = {
        color: "rgba(71, 75, 79, 0.8)",
        letterSpacing: "0px",
        fontStyle: "normal",
        fontVariant: "normal",
        fontSize: "12px",
        fontFamily: "Roboto",
        textAlign: "justify",
        lineHeight: "20px"
    }

    return (
        <Grid>
            {TimeSlotAutocomplete()}
            <Box display={"flex"}>
                <span style={{ ...infoStyle, fontWeight: 450, marginRight: "5px" }}>{t('COMMON010')} </span>
                <Typography sx={{ ...infoStyle, lineHeight: "20px" }}>
                    {t(`AccDoorAccTime017`)}</Typography>
            </Box>
            <Grid
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    marginTop: { xl: "30px", xs: "20px" }
                }}>
                <Button
                    id="button-confirm-modal-delete"
                    variant='contained'
                    onClick={() => handleSubmit()}
                    sx={{
                        width: { xl: '165px', xs: "150px" },
                        height: { xl: '64px', xs: "50px" },
                        marginRight: { xl: "30px", xs: "20px" }
                    }}
                >
                    {t("COMMON029")}
                </Button>
                <Button
                    id="button-confirm-modal-cancel"
                    onClick={() => props.onClose()}
                    variant='outlined'
                    sx={{
                        width: { xl: '165px', xs: "150px" },
                        height: { xl: '64px', xs: "50px" },
                    }}
                >
                    {t("COMMON009")}
                </Button>
            </Grid>
        </Grid>
    );
}

export default DoorAccessTimeSlot
