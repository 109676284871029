import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, InputLabel, MenuItem, Pagination, Select, TablePagination, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import NoRecordFound from '../NoRecordFound';
import Loader from '../Loader';

export default function DataTable(props) {
  const { t } = useTranslation()

  const customDataGridStyle = {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#F5F6F6 !important',
      color: 'rgba(71, 75, 79, 0.8) !important',
      letterSpacing: '0.4px',
      fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
      fontWeight: 500,
      lineHeight: "19px"
    },
    '& .MuiDataGrid-cell': {
      color: 'rgba(71, 75, 79, 0.8) !important',
      letterSpacing: '0.4px',
      fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
      border: "none",
      lineHeight: "19px"
    },
    '&.MuiDataGrid-root': {
      border: '1px solid rgba(245, 246, 246, 1) !important',
      borderRadius: "8px"
    },
    '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
    '.MuiDataGrid-cell:focus-within': {
      outline: 'none!important',
      color: '#76B745!important',
    },
    '.MuiDataGrid-columnSeparator': { display: 'none !important' },
    '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
    '.MuiDataGrid-row': {
      height: '60px !important',
      backgroundColor: '#FFFF !important',
      '&:hover': {
        backgroundColor: '#F5F6F6 !important',
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(118, 183, 69, 12%) !important',
      }
    },
    '.MuiCheckbox-root': { color: '#D1D1D1' },
    '.MuiDataGrid-cellContent': {
      color: 'rgba(71, 75, 79, 0.8) !important',
      fontFamily: 'Roboto, Regular',
      letterSpacing: '0.4px',
      fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
      lineHeight: "19px"
    },
    '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF' },
    '.MuiDataGrid-footerContainer': {
      display: 'block',
      width: '100%',
      border: '0px',
    },
    '.MuiTablePagination-spacer': { display: 'none' },
    '.MuiTablePagination-actions': {
      position: 'absolute',
      right: '0px',
      backgroundColor: '#FFF',
    },
    '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
    ".MuiDataGrid-virtualScroller": {
      overflowY: "auto !important",
    },
    '.MuiTablePagination-select': {
      backgroundColor: '#F0F1F4',
      borderRadius: '4px!important',
    },
    '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
    '.MuiIconButton-root.Mui-disabled': {
      backgroundColor: '#F0F1F4',
      borderRadius: '0px',
    },
    '.MuiIconButton-root': { borderRadius: '0px' },
    '.Mui-checked': { color: '#76B745!important' },

    '.MuiDataGrid-columnHeaderTitleContainerContent': {
      lineHeight: '19px!important'
    }
  };

  const paginationStyles = {
    button: {
      backgroundColor: "#FFF",
      color: 'rgba(71, 75, 79, 0.8)',
      fontSize: { xl: '16px', lg: "14px", md: "14px", sm: "14px", xs: "14px" },
      width: { xl: '50px', lg: "40px", md: "40px", sm: "40px", xs: "40px" },
      height: { xl: '50px', lg: "40px", md: "40px", sm: "40px", xs: "40px" },
      lineHeight: "19px"
    },
    ul: {
      "& .Mui-selected": { backgroundColor: "#76B745", color: 'white' },
      "&:hover .Mui-selected": { backgroundColor: "#76B745" },
      "& .MuiPaginationItem-previousNext": {
        backgroundColor: "#F5F6F6",
      }
    }
  };
  const getPageDetails = () => {
    var pageRange = '0 - 0 of 0';
    var page = props.page + 1;
    if (props.totalRecords > 0) {
      let lastRecordIndex = page * props.rowsPerPage;
      if (lastRecordIndex <= props.totalRecords) {
        let firstRecordIndex = lastRecordIndex - props.rowsPerPage + 1;
        pageRange = firstRecordIndex + '-' + lastRecordIndex + ' of ' + props.totalRecords;
      }
      else {
        let firstRecordIndex = lastRecordIndex - props.rowsPerPage + 1;
        pageRange = firstRecordIndex + '-' + props.totalRecords + ' of ' + props.totalRecords;
      }
    }
    return pageRange;
  };

  const [loader, setLoader] = React.useState(true);

  const setLoaderFunction = () => {
    let loader = localStorage.getItem('isLoading')
    if (loader === 'true') {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }
  React.useEffect(() => {
    window.addEventListener('storage', setLoaderFunction);
    return () => {
      window.removeEventListener('storage', setLoaderFunction);
    };
  }, []);

  return (
    <Box width={'100%'}>
      <Box
        sx={{
          width: '100%',
          height: props.tableHeight ? props.tableHeight : { xl: '670px', lg: "65vh", md: "60vh", sm: "60vh", xs: "60vh" },
        }}>
        <DataGrid
          sx={{
            ...customDataGridStyle
          }}
          {...props}
          rowHeight={60}
          getRowId={props.getRowId}
          hideFooter='true'
          paginationMode='server'
          page={props.page}
          rows={props.rows}
          columns={props.columns}
          pagination
          rowCount={props.totalRecords}
          checkboxSelection={props.checkboxSelection}
          hideFooterSelectedRowCount={true}
          pageSize={props.rowsPerPage}
          // disableRowSelectionOnClick={true}
          onRowSelectionModelChange={(params) => {
            props.onSelection(params);
          }}
          loading={props.loaderData != undefined ? props.loaderData : loader}
          components={{
            Toolbar: props.toolbar,
            NoRowsOverlay: () => {
              if (!loader) {
                return <NoRecordFound text={props.noRecordText} />;
              } else {
                return null;
              }
            },
          }}
        />
      </Box>
      {(props.totalRecords >= 5) ? (props.hidePagination === undefined) &&
        <Box
          sx={{
            paddingTop: { xl: '30px', lg: "15px", md: "15px", sm: "15px", xs: "15px" },
            display: "flex",
            alignItems: "center",
            justifyContent: { xl: 'space-between', xs: "space-between" }
          }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xl: 'row',
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column"
            }
          }}>
            <Box sx={{ lineHeight: '21px!important' }}>
              <Typography sx={{ lineHeight: '21px!important' }} variant='labelOverflow'>{t("COMMON028")}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: {
                  xl: 2,
                  lg: 2,
                  md: 2,
                  sm: 0,
                  xs: 0
                }
              }}
            >
              <Select
                // native
                sx={{
                  backgroundColor: "rgba(245, 246, 246, 1)",
                  '& .MuiOutlinedInput-input': {
                    padding: "0px 32px 0px 0px !important",
                    fontSize: '16px !important',
                    lineHeight: '23px !important',
                    fontFamily: "Roboto !important",
                    color: 'rgba(71, 75, 79, 0.8) !important',
                    display: "flex !important",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    width: { xl: '67px !important', xs: "38px !important" },
                    height: { xl: '50px !important', xs: "40px !important" },
                  }
                }}
                value={props.rowsPerPage}
                inputProps={{
                  name: 'page size',
                  id: 'filled-age-native-simple',
                }}
                onChange={(e) => props.onPageSizeChange(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root.Mui-selected": {
                        color: " #76B745",
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#F4FCF7",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        color: " #76B745",
                      },
                    },
                  },
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>

              <Box ml={2}>
                <Typography variant='labelOverflow'>{getPageDetails()}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: { lg: "block", sm: "none", xs: "none" } }}>
            <Pagination
              count={Math.ceil(props.totalRecords / props.rowsPerPage)}
              page={props.page + 1}
              sx={paginationStyles}
              shape='rounded'
              onChange={(e, v) => props.onPageChange(v - 1)} />
          </Box>
          <Box item sx={{ display: { lg: "none", sm: "block", xs: "block" } }}>
            <TablePagination
              component="div"
              count={Math.ceil(props.totalRecords / props.rowsPerPage)}
              page={props.page}
              onPageChange={(e, v) => props.onPageChange(v)}
              labelDisplayedRows={() => ''}
              rowsPerPageOptions={[]}
              nextIconButtonProps={{
                disabled:
                  props.page >= Math.ceil(props.totalRecords / props.rowsPerPage) - 1,
              }}
            />
          </Box>

        </Box>
        : <></>}
    </Box>
  );
}

DataTable.defaultProps = {
  rows: [],
  columns: [],
};
