import React, { useEffect, useState } from 'react';
import RightViewLayout from '../../components/RighViewLayout';
import { ReactComponent as Refresh } from "../../assets/new-images/Refresh Icon.svg";
import { ReactComponent as Filter } from "../../assets/new-images/Filter icon (1).svg";
import { ReactComponent as AddDevice } from "../../assets/new-images/Plus icon.svg";
import { ReactComponent as PersonInDevice } from "../../assets/DeviceIcon/personInDeviceIcon.svg";
import { ReactComponent as Control } from "../../assets/DeviceIcon/Control Icon.svg";
import { ReactComponent as Delete } from "../../assets/DeviceIcon/Delete Icon.svg";
import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import { deleteDevice, filterDevices, rebootDevice, updateDevice } from '../../services/DeviceService';
import CommonUtil from '../../util/CommonUtils';
import DataTable from '../../components/layout-components/DataTable';
import { ReactComponent as DeleteOne } from "../../assets/new-images/Delete Icon.svg";
import { ReactComponent as Edit } from "../../assets/new-images/Edit Icon.svg";
import CustomPopState from '../../components/CustomPopState';
import CustomDialogBox from '../../components/CustomDialogBox';
import { useLocation, useNavigate } from 'react-router';
import { REACT_URL_ADD_DEVICE_INSTRUCTION, REACT_URL_PERSON_IN_DEVICE, REACT_URL_UPDATE_DEVICE } from '../../constants/ReactEndPoints';
import CustomSwitch from '../../components/CustomSwitch';
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { useSnackbar } from 'notistack';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import newDevieImage from '../../assets/ViewDevice.png';
import { DeviceTableImage } from './DeviceImage';


const DeviceList = () => {
  const { t } = useTranslation()
  const defaultFilterData = {
    deviceSn: '',
    deviceAlias: ''
  };

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(defaultFilterData);
  const location = useLocation();
  const [page, setPage] = React.useState(location?.state?.pageNumber ? location?.state?.pageNumber : 1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize ? location?.state?.pageSize : 10);
  const [selectedDevices, onSelectionChange] = useState([]);
  const [openReboot, setOpenReboot] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSetup, setOpenSetup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [del, SetDel] = useState(false);
  const [deviceName, SetDeviceName] = useState("");
  const [pageSize, setPageSize] = useState(0);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    id: '',
    payload: { registrationDevice: null }
  });
  const [filter, setFilter] = useState(false);
  const [load, setLoad] = useState(true);
  const [perReboot, setReboot] = useState(true);
  const [perAddDevice, setAddDevice] = useState(true);
  const [perDeleteDevice, setDeleteDevice] = useState(true);
  const [perEditDevice, setEditDevice] = useState(true);
  const [perListDevice, setListDevice] = useState(true);
  const [personInDevice, setPersonIndevice] = useState(true);
  const [change, setChange] = useState(false);
  const [reload, setReload] = React.useState(false);
  const [clearAndRefresh, setClearAndRefresh] = React.useState(false);

  useEffect(() => {
    if (load && !filter) {
      getPermissionsByRoleId(api.decoded().roleId).then((res) => {
        var l = [];
        res.data.data.rolePermissions.map((item, index) => {
          l.push(item.code);
        });
        setRoles(l);
        setReboot(l.includes("ACC_REBOOT_DEVICE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setAddDevice(l.includes("EDIT_DEVICE_LIST_DETAILS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setDeleteDevice(l.includes("ACC_DEVICE_DELETE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setEditDevice(l.includes("EDIT_DEVICE_LIST_DETAILS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListDevice(l.includes("VIEW_DEVICE_LIST_DETAILS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
        setPersonIndevice(l.includes("EDIT_DEVICE_LIST_DETAILS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
        if (l.includes("VIEW_DEVICE_LIST_DETAILS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          loadData(page, rowsPerPage, defaultFilterData);
        }
      });
    }
  }, [page, rowsPerPage]);


  const [roles, setRoles] = React.useState(['']);

  useEffect(() => {
    if (filter && !load && reload) {
      loadData(1, rowsPerPage, filterData);
      setReload(false)
    }
  }, [filter, reload]);


  const handleChange = (event) => {
    const name = event.target.name;
    setFilterData({
      ...filterData,
      [name]: event.target.value
    });
    setChange(true);
  };

  const loadData = (pageNumber, pageSize, filter) => {
    onSelectionChange([]);
    const payload = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      filter: filter
    }
    filterDevices(payload, (res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data?.devices)) {
        setTotalRecords(res.data.data?.totalCount);
        setData(res.data.data.devices);
        setPageSize((res.data.data.devices).length);
      } else {
        setTotalRecords(0);
        setData([]);
      }
      setClearAndRefresh(false);
    });
  }
  // This will come in future do not delete column. 

  const handleSwitchChange = (e, row) => {
    setState({
      id: row.id,
      registrationDevice: e.target.checked === true ? 1 : 0
    });
    setOpenSetup(!openSetup)
  }

  const columns = [
    {
      field: 'deviceAlias', hideable: false, headerName: t('AccDevice014'), tooltip: t('AccDevice014'), flex: 1.5,
      renderCell: (e) => (

        <> <Box width={"50px"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} mr={1}>
          {DeviceTableImage[e.row.deviceModel] ? DeviceTableImage[e.row.deviceModel] : <img alt='default' src={newDevieImage} style={{ height: "45px" }} />}
        </Box><Box sx={{ color: 'rgba(71, 75, 79, 0.8) !important' }}>{e.row.deviceAlias}</Box></>
      )
    },

    { field: 'sn', headerName: t('AccDoor001'), tooltip: t('AccDoor001'), flex: 0.7 },

    { field: 'ip', headerName: t('AccDevice048'), tooltip: t('AccDevice048'), flex: 0.7 },

    { field: 'deviceModel', headerName: t('AccDevice049'), tooltip: t('AccDevice049'), flex: 1 },

    { field: 'fwVersion', headerName: t('AccDevice050'), tooltip: t('AccDevice050'), flex: 1 },

    {
      field: 'status', headerName: t('AccDevice051'), tooltip: t('AccDevice051'), flex: 0.5,
      renderCell: (rowData) => (
        <span
          style={{
            marginRight: '10px',
            fontWeight: 'bold',
            color: rowData.row.status === 1 ? 'green' : 'red',
          }}
        >
          {rowData.row.status === 1 ? 'Online' : 'Offline'}
        </span>
      ),
    },
    // This will come in future do not delete column. 

    // {
    //   field: 'registrationDevice', headerName: 'Automatic Backup Device', tooltip: 'Automatic Backup Device', flex: 1,
    //   renderCell: (rowData) => (
    //     <>
    //       <Tooltip title={rowData.row.registrationDevice === true ? "Yes" : "No"}>
    //         <Box>
    //           <CustomSwitch
    //             checked={rowData.row.registrationDevice}
    //             handleChange={(e) => handleSwitchChange(e, rowData.row)} />
    //         </Box>
    //       </Tooltip>
    //     </>
    //   )
    // },

    {
      field: 'actions', hideable: false, headerName: t('COMMON019'), type: 'actions', flex: 1.2,
      renderCell: (e) => (
        <Grid>
          <GridActionsCellItem
            icon={personInDevice ?
              (<Tooltip title={t("AccDevice052")}><PersonInDevice id="icon-devicelist-person" style={{ cursor: 'pointer' }}
                onClick={() => navigate(REACT_URL_PERSON_IN_DEVICE + `/${e.row.sn}`, { state: { data: e.row, pageNumber: page, pageSize: rowsPerPage } })}
              /></Tooltip>) : <Box></Box>}
          />
          <GridActionsCellItem
            sx={{ marginRight: { xl: "20px", xs: "10px" } }}
            icon={perEditDevice ? (<Tooltip title={t("COMMON005")}><Edit id="icon-devicelist-edit" style={{ cursor: 'pointer' }}
              onClick={() => navigate(REACT_URL_UPDATE_DEVICE + `/${e.row.sn}`, { state: e.row, pageNumber: page, pageSize: rowsPerPage })}
            /></Tooltip>) : <Box></Box>}
          />
          <GridActionsCellItem
            icon={perDeleteDevice ?
              (<Tooltip title={t("COMMON008")}>< DeleteOne id="icon-devicelist-delete" style={{ cursor: 'pointer' }}
                onClick={() => { handleDelete(e.row.id); SetDeviceName(e.row.deviceAlias) }}
              /></Tooltip>) : <Box></Box>}
          />
        </Grid>
      )
    }
  ];

  const handleReboot = () => {
    if (!CommonUtil.isEmpty(selectedDevices)) {
      setOpenReboot(!openReboot);
    }
    else {
      enqueueSnackbar(t("AccDevice047"), {
        variant: 'error',
      });
    }
  }

  const handleDelete = (id) => {
    if (!CommonUtil.isEmpty(selectedDevices) && id === undefined) {
      setOpenDelete(!openDelete);
    }
    else if (id !== undefined) {
      let ids = [];
      ids.push(id)
      onSelectionChange(ids);
      setOpenDelete(!openDelete);
    }
    else {
      enqueueSnackbar(t("AccDevice047"), {
        variant: 'error',
      });
    }
  }

  const handleCloseReboot = () => {
    setOpenReboot(false);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
    onSelectionChange([]);
  }

  const handleSubmitReboot = () => {
    let ids = [];
    let offlineDevIds = [];
    let deviceNames = [];
    selectedDevices.map((val) => {
      var sn = getDeviceOnlineStatus(val);
      if (sn != '') {
        offlineDevIds.push(val)
      } else {
        ids.push(val);
        data.map((device) => {
          if (device.id == val) {
            deviceNames.push({ name: device.deviceAlias, ip: device.ip });
          }
        });
      }
    });

    if (!CommonUtil.isEmpty(offlineDevIds)) {
      enqueueSnackbar(t("AccDevice021"), { variant: 'error' });
    }

    if (!CommonUtil.isEmpty(selectedDevices) && !CommonUtil.isEmpty(ids)) {
      rebootDevice(ids, deviceNames, (res) => {
        if (res?.data?.code === 'CACCSI0001') { enqueueSnackbar(t("AccDevice020"), { variant: 'success' }) }
        else if (res?.data?.code === 'CACCSW0001') { enqueueSnackbar(res.data.msg, { variant: 'warning' }) }
        else { enqueueSnackbar(res.data.msg, { variant: 'error' }); }
      });
    }


    setOpenReboot(false);

    return;
  }

  const getDeviceOnlineStatus = (id) => {
    var ret = '';
    data.map((device, index) => {
      if (device.id == id && device.status == 0) {
        //console.log(device);
        ret = device.sn;
      }
    });
    return ret;
  }

  const handleSubmitDelete = () => {
    let ids = [];
    let deviceNames = [];
    selectedDevices.map((val) => {
      ids.push(val);
      data.map((device) => {
        if (device.id == val) {
          deviceNames.push({ name: device.deviceAlias, ip: device.ip });
        }
      });
    })
    if (!CommonUtil.isEmpty(selectedDevices)) {
      deleteDevice(ids, deviceNames, (res) => {
        if (res?.data?.code === 'CACCSI0002') {
          enqueueSnackbar(t('AccGroupAccessTime047'), { variant: 'success' })
          SetDel(true);
        } else if (res != null && res.data.code === 'CACCSW0001') {
          enqueueSnackbar(t('AccGroupAccessTime048'), { variant: 'warning' })
        } else {
          enqueueSnackbar(res.data.msg, { variant: 'error' })
        }
        setOpenDelete(false);
      });
    }
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage, defaultFilterData);
      SetDel(false);
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage, defaultFilterData);
        SetDel(false);
      }
    }
  }, [del]);


  useEffect(() => {
    if (filterData.deviceAlias === '' && filterData.deviceSn === '') {
      setDisabled(true);
      if (change) {
        setFilter(false); setLoad(true);
        if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilterData);
        else {
          if (!clearAndRefresh)
            setPage(1);
        }
      }
    } else setDisabled(false);
  }, [filterData]);

  // This will come in future do not delete function. 
  const handleCloseSetup = () => {
    setOpenSetup(false);
  }
  const DEFAULT_PAGE_SIZE = 10;

  const handleRefresh = () => {
    setClearAndRefresh(true);
    if (page === 1) {
      setFilterData(defaultFilterData);
      setDisabled(true)
      setFilter(false);
      setLoad(true);
      loadData(1, DEFAULT_PAGE_SIZE, defaultFilterData);
      setRowsPerPage(DEFAULT_PAGE_SIZE);

    }
    else {
      setFilterData(defaultFilterData);
      setDisabled(true)
      setFilter(false);
      setLoad(true);
      setPage(1);
      setRowsPerPage(DEFAULT_PAGE_SIZE);

    }
  }
  // This will come in future do not delete function. 
  const handleSubmitSetup = () => {

    if (!CommonUtil.isEmpty(state.id)) {
      updateDevice(state).then((res) => {
        if (res?.data?.code === 'DMSI0000') {
          enqueueSnackbar(
            state.registrationDevice === 1 ? t('AccDevice017') : t('AccDevice018'),
            { variant: 'success' });
          loadData(page, rowsPerPage, filterData);
          setOpenSetup(false);
        } else if (res?.data?.code === 'DMSE00613') {
          enqueueSnackbar(t('AccDevice019'), { variant: 'error' });
          setOpenSetup(false);
        } else {
          enqueueSnackbar(res.data.msg, { variant: 'error' });
        }
      });
    }
  }

  const actions = () => (
    <Box item display={"flex"} >
      <Tooltip title={t('ACCORG008')}>
        <Box
          aria-label="refresh"
          onClick={() => handleRefresh()}
          mr={2}
          sx={{ display: { xl: "block", xs: "none" } }}
          id={"refresh-Icon"}
        >
          <Refresh style={{ cursor: 'pointer' }} />
        </Box>
        <Box
          aria-label="refresh"
          onClick={() => handleRefresh()}
          mr={2}
          sx={{ display: { xl: "none", xs: "block" } }}
        >
          <Refresh width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
        </Box>
      </Tooltip>
      {perReboot &&
        (<>
          <Box
            aria-label="devicelist-control"
            mr={2}
            sx={{ display: { xl: "block", xs: "none" } }}
            id={"devicelist-control-Icon"}
          >
            <CustomPopState icon={<Control id="icon-devicelist-control" width={'50px'} style={{ cursor: 'pointer' }} />}
              list={[{ title: t('AccDevice012') }]}
              tooltip={t('AccDevice015')}
              handleClick={() => handleReboot()}
            />
          </Box>
          <Box
            aria-label="devicelist-control"
            mr={2}
            sx={{ display: { xl: "none", xs: "block" } }}
            id={"devicelist-control-Icon"}
          >
            <CustomPopState icon={<Control id="icon-devicelist-control" width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />}
              list={[{ title: t('AccDevice012') }]}
              tooltip={t('AccDevice015')}
              handleClick={() => handleReboot()}
            />
          </Box>
        </>)}
      {perDeleteDevice &&
        (<Tooltip title={t("COMMON008")}>
          <Box
            aria-label="refresh"
            onClick={() => handleDelete()}
            mr={2}
            sx={{ display: { xl: "block", xs: "none" } }}
            id={"refresh-Icon"}
          >
            <Delete style={{ cursor: 'pointer' }} />
          </Box>
          <Box
            aria-label="refresh"
            onClick={() => handleDelete()}
            mr={2}
            sx={{ display: { xl: "none", xs: "block" } }}
          >
            <Delete width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>)}

      <Tooltip title={t('ACCORG009')}>
        <Box
          aria-label="filter"
          onClick={() => setOpenFilter(!openFilter)}
          sx={{ display: { xl: "block", xs: "none" } }}
          id={"filter-Icon"}
          mr={2}
        >
          <Filter style={{ cursor: 'pointer' }} />
        </Box>
        <Box
          aria-label="refresh"
          onClick={() => setOpenFilter(!openFilter)}
          sx={{ display: { xl: "none", xs: "block" } }}
          id={"filter-Icon"}
          mr={2}
        >
          <Filter width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
        </Box>
      </Tooltip>

      {perAddDevice &&
        (<>
          <Tooltip title={t('Add Device')}>
            <Box
              aria-label="add-device"
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"add-device-Icon"}
            >
              <AddDevice id="icon-devicelist-add" style={{ cursor: 'pointer' }}
                onClick={() => navigate(REACT_URL_ADD_DEVICE_INSTRUCTION)}
              />
            </Box>
            <Box
              aria-label="add-device"
              sx={{ display: { xl: "none", xs: "block" } }}
              id={"add-device-Icon"}
            >
              <AddDevice id="icon-devicelist-add" height={"40px"} width={"40px"} style={{ cursor: 'pointer' }}
                onClick={() => navigate(REACT_URL_ADD_DEVICE_INSTRUCTION)}
              />
            </Box>
          </Tooltip>
        </>)}
    </Box>
  );

  const FieldDeviceName = (key) => (
    <CustomInput
      id="textfield-devicelist-devicename"
      placeholder={t('AccDevice014')}

      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      value={filterData.deviceAlias}
      size='small'
      onKeyDown={handleKeyPress}
      fullWidth
      name='deviceAlias'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}

    />
  );
  const FieldSerialNumber = (key) => (
    <CustomInput
      id="textfield-devicelist-sn"
      placeholder={t('AccDevice013')}

      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      value={filterData.deviceSn}
      onKeyDown={handleKeyPress}
      size='small'
      fullWidth
      name='deviceSn'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}
    />
  );

  const clearFilter = () => {
    setFilterData(defaultFilterData);
    setClearAndRefresh(true)
    setDisabled(true);
    setFilter(false);
    setLoad(true);
    if (page === 1) {
      loadData(1, DEFAULT_PAGE_SIZE, defaultFilterData);
      setRowsPerPage(DEFAULT_PAGE_SIZE);

    }
    else setPage(1);
    setRowsPerPage(DEFAULT_PAGE_SIZE);

  }

  const handleSubmitFilter = () => {
    setFilter(true);
    setLoad(false);
    setReload(true);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilter();
    }
  };
  const handlePageChange = (e) => {
    if (filter) {
      setPage(e);
      loadData(e, rowsPerPage, filterData);
    }
    else {
      setFilter(false);
      setLoad(true);
      setPage(e);
    }
  }

  const handlePageSize = (e) => {
    if (filter) {
      setRowsPerPage(e);
      setPage(1);
      loadData(1, e, filterData);
    }
    else {
      setFilter(false);
      setLoad(true);
      setRowsPerPage(e);
      setPage(1);
    }
  }

  useEffect(() => {
    if (!openFilter && !disabled) {
      handleRefresh();
    }
  }, [openFilter])


  return (
    <>
      {perListDevice ? (<> <RightViewLayout
        navigateBack={true}
        title={t("AccDevice001")}
        actions={actions}
      />
        <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
          {openFilter && <FilterComponent
            firstField={FieldDeviceName()}
            secondField={FieldSerialNumber()}
            clearFilter={clearFilter}
            handleSubmit={handleSubmitFilter}
            disabled={disabled}
          />}
          <DataTable
            onSelection={(rows) => onSelectionChange(rows)}
            rowSelectionModel={selectedDevices}
            page={page - 1}
            totalRecords={totalRecords}
            rowsPerPage={rowsPerPage}
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            checkboxSelection={true}
            onPageChange={(e) => handlePageChange(e + 1)}
            onPageSizeChange={(e) => handlePageSize(e)}
            noRecordText={t("AccDevice002")}
          />
        </Box>
      </>) : (<Box marginTop={'150px'} width={'100%'}>
        <Typography textAlign={'center'}>{t('AccDevice003')} </Typography>
      </Box>)}
      <CustomDialogBox
        id="rebootDevcie"
        open={openReboot}
        customWidth={'420px'}
        title={t("COMMON013")}
        message={selectedDevices.length < 2 ? t("AccDevice004") : t("AccDevice005")}
        saveButtonName={t("AccDevice012")}
        cancelButtonName={t("COMMON009")}
        onClose={handleCloseReboot}
        handleSubmit={handleSubmitReboot}
      />

      <CustomDialogBox
        id="deleteDevcie"
        open={openDelete}
        customWidth={'420px'}
        title={t("COMMON013")}
        message={selectedDevices.length < 2 ? t("AccDevice006") : t("AccDevice007")}
        saveButtonName={t("COMMON008")}
        cancelButtonName={t("COMMON009")}
        onClose={handleCloseDelete}
        handleSubmit={handleSubmitDelete}
      />
      {/* This will come in future do not delete component.  */}
      <CustomDialogBox
        id="setupDevcie"
        open={openSetup}
        customWidth={'420px'}
        title={t("COMMON013")}
        message={state.registrationDevice === 1 ? ("AccDevice008") :
          t("AccDevice009")}
        saveButtonName={t("COMMON011")}
        cancelButtonName={t("COMMON012")}
        onClose={handleCloseSetup}
        handleSubmit={handleSubmitSetup}
        note={
          <> <Box width={400} marginTop={2}> <span style={{ fontWeight: 'bold' }}>{t("COMMON010")}</span> {t("AccDevice011")}
            {("AccDevice010")} </Box>
          </>
        }
      />
    </>
  )
}


export default DeviceList
