import React, { useEffect, useState } from 'react'
import { FilterComponent } from '../../components/FilterComponent';
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from '../../components/RighViewLayout';
import { Autocomplete, Box, TextField, Tooltip, Typography } from '@mui/material';
import CustomInput from '../../components/CustomInput';
import CommonUtil from '../../util/CommonUtils';
import { filterOperation } from '../../services/OperationService';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import ExportIcon from './OperationLogExport'

const OperationLog = () => {

  const [data, setData] = React.useState([]);
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [viewOperationList, setViewOperationList] = React.useState(true);
  const [ids, setIds] = useState([]);

  const [filter, setFilter] = useState(false);
  const [load, setLoad] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [change, setChange] = useState(false);
  const [reload, setReload] = useState(false);
  const [clearAndRefresh, setClearAndRefresh] = useState(false);
  const [columnSelected, setColumnSelected] = useState([])
  const [selectedDataColumn, setSelectedDataColumn] = useState(null)
  const defaultFilter = {
    executingState: '',
    operator: '',
  };
  const [filterData, setFilterData] = useState({
    ...defaultFilter,
  });

  useEffect(() => {
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      var l = [];
      res.data.data.rolePermissions.map((item, index) => {
        l.push(item.code);
      });
      setRoles(l);
      setViewOperationList(l.includes("OPERATOR_LOGS") || (api.decoded() !== undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
      if (l.includes("OPERATOR_LOGS")) {
        loadData(page, rowsPerPage, defaultFilter);
      }
    });
  }, []);

  useEffect(() => {
    if (roles.includes("OPERATOR_LOGS") || (api.decoded() !== undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
      if (load && !filter)
        loadData(page, rowsPerPage, defaultFilter);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (filter && !load && reload) {
      loadData(1, rowsPerPage, filterData);
      setReload(false)
    }
  }, [filter, reload]);

  // Delete End

  const loadData = (pageNumber, pageSize, filterData) => {
    setIds([]);
    const payload = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      filter: filterData
    }
    filterOperation(payload, (res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'CASI0001') {
        setTotalRecords(res.data.data.totalCount);
        setData(res.data.data.operationLogs);
        setClearAndRefresh(false);
      } else {
        setTotalRecords(0);
        setData([]);
        setClearAndRefresh(false);
      }
    });
  };

  const getDateAndTime = (date) => {
    var dt = new Date(date.replace(' ', 'T'));
    return CommonUtil.getLocalDate(dt);
  }

  const columns = [

    { field: 'operator', hideable: false, headerName: t('ACCOPERALOG001'), tooltip: t('ACCOPERALOG001'), flex: 1 },
    { field: 'operatorTime', headerName: t('ACCOPERALOG002'), tooltip: t('ACCOPERALOG002'), flex: 1, valueGetter: (e) => e.row.createdAt ? getDateAndTime(e.row.createdAt) : '' },
    { field: 'deviceIpAddress', headerName: t('ACCOPERALOG003'), tooltip: t('ACCOPERALOG003'), flex: 1 },
    { field: 'operateFunction', headerName: t('ACCOPERALOG004'), tooltip: t('ACCOPERALOG004'), flex: 1 },
    { field: 'executingState', headerName: t('ACCOPERALOG005'), tooltip: t('ACCOPERALOG005'), flex: 1 },
    { field: 'description', headerName: t('ACCSITE003'), tooltip: t('ACCSITE003'), flex: 3 },
  ];

  const DEFAULT_PAGE_SIZE = 10;

  const refreshTableData = () => {
    setClearAndRefresh(true);
    if (page === 1) {
      setFilterData(defaultFilter);
      setDisabled(true)
      setFilter(false);
      setLoad(true);
      loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else {
      setFilterData(defaultFilter);
      setDisabled(true)
      setFilter(false);
      setLoad(true);
      setPage(1);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
  };

  // Filter  Related
  const handleChange = (event) => {
    setChange(true);
    const name = event.target.name;
    setFilterData({
      ...filterData,
      [name]: event.target.value
    });
  };

  useEffect(() => {
    if (filterData.executingState === '' && filterData.operator === '') {
      setDisabled(true);
      if (change) {
        setFilter(false); setLoad(true);
        if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
        else {
          if (!clearAndRefresh)
            setPage(1);
        }
      }
    } else setDisabled(false);
  }, [filterData]);

  const clearFilter = () => {
    setFilterData(defaultFilter);
    setDisabled(true);
    setFilter(false);
    setLoad(true);
    setChange(false)
    if (page === 1) {
      loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
      setRowsPerPage(DEFAULT_PAGE_SIZE);
    }
    else setPage(1);
    setRowsPerPage(DEFAULT_PAGE_SIZE);
  }

  const handleSubmitFilter = () => {
    setFilter(true);
    setLoad(false);
    setReload(true)
  }

  const handlePageChange = (e) => {
    if (filter) {
      setPage(e);
      loadData(e, rowsPerPage, filterData);
    }
    else {
      setFilter(false);
      setLoad(true);
      setPage(e);
    }
  }

  const handlePageSize = (e) => {
    if (filter) {
      setRowsPerPage(e);
      setPage(1);
      loadData(1, e, filterData);
    }
    else {
      setFilter(false);
      setLoad(true);
      setRowsPerPage(e);
      setPage(1);
    }
  }

  const ExecutingStateList = [
    {
      id: 'success',
      value: t('COMMON035'),
    },
    {
      id: 'failure',
      value: t('COMMON036'),
    },
  ];

  useEffect(() => {
    if (!open && !disabled) {
      refreshTableData();
    }
  }, [open])


  const FieldOperator = (key) => (
    <CustomInput
      id="textfield-operationlog-operator"
      placeholder={t('ACCOPERALOG001')}
      height={{ xl: "50px", xs: "40px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      value={filterData.operator}
      size='small'
      onKeyDown={handleKeyPress}
      fullWidth
      name='operator'
      variant='outlined'
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}
    />
  );

  const findExecutingState = (value) => {
    var executingState = '';
    ExecutingStateList.map((data) => {
      if (data.id === value) {
        executingState = data;
      }
    });
    return executingState;
  };

  const FieldExicutingState = (key) => (

    <Autocomplete
      id="dropdown-operationlog-executingstate"
      fullWidth
      size={'small'}
      onKeyDown={handleKeyPress}
      getOptionLabel={(option) => (option.id ? option.value : '')}
      clearText='false'
      options={ExecutingStateList}
      value={findExecutingState(filterData.executingState)}
      renderInput={(params) => <CustomInput
        height={{ xl: "33px", xs: "24px" }}
        padding={{ xl: "0px 20px", xs: "0px 10px" }}
        handleChange={(e) => console.log()}
        placeholder={t('ACCOPERALOG005')} {...params} cursor='pointer' />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setFilter(false);
          setFilterData({
            ...filterData,
            executingState: newValue.id
          });
        }
        else {
          if (!CommonUtil.isEmptyString(filterData.executingState)) {
            setFilterData({
              ...filterData,
              executingState: "",
            });
          }
        }
        setChange(true)
      }}
    />
  );

  let selectedFields = [
    'operatorTime', 'deviceIpAddress', 'operateFunction',
    'executingState', 'description'
  ]

  useEffect(() => {
    getSelectedColumn()
  }, [columnSelected])
  let updateSelectedValues = []
  const getSelectedColumn = () => {
    let unCheckedValues = []
    Object.entries(columnSelected).forEach(([key, value]) => {
      if (value === false) {
        unCheckedValues.push(key)
      }
    })
    updateSelectedValues = selectedFields.filter(column => !unCheckedValues.includes(column))
    setSelectedDataColumn(updateSelectedValues)
    console.log(updateSelectedValues);
  }

  const actions = () => (
    <>
      {viewOperationList &&
        <Box display={"flex"} sx={{ height: { xl: "50px", xs: "40px" } }}>
          <Tooltip title={t('ACCORG008')}>
            <Box
              aria-label="refresh"
              onClick={() => refreshTableData()}
              mr={2}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"refresh-Icon"}
            >
              <RefreshIcon style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="refresh"
              onClick={() => refreshTableData()}
              mr={2}
              sx={{ display: { xl: "none", xs: "block" } }}
            >
              <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip>
          <Tooltip title={t('ACCORG009')}>
            <Box
              aria-label="filter"
              onClick={() => setOpen(!open)}
              sx={{ display: { xl: "block", xs: "none" } }}
              id={"filter-Icon"}
              mr={2}
            >
              <FilterIcon style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              aria-label="refresh"
              onClick={() => setOpen(!open)}
              sx={{ display: { xl: "none", xs: "block" } }}
              id={"filter-Icon"}
              mr={2}
            >
              <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
            </Box>
          </Tooltip>

          <Box
            aria-label="export"
            id={"export-Icon"}
          >
            <ExportIcon style={{ cursor: 'pointer' }} totalRecords={totalRecords} ids={ids} selectedColumn={selectedDataColumn} executingState={filterData.executingState} operator={filterData.operator} />
          </Box>
        </Box>
      }
    </>
  );

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilter();
    }
  };

  const onSelectChecxBoxChange = (id) => {
    setIds(id);
  };



  return (
    <>
      {
        viewOperationList ? (<> <RightViewLayout
          navigateBack={true}
          title={t('ACCOPERALOG006')}
          actions={actions}
          type='table'
        />
          <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
            {open && <FilterComponent
              firstField={FieldOperator()}
              secondField={FieldExicutingState()}
              clearFilter={clearFilter}
              handleSubmit={handleSubmitFilter}
              disabled={disabled}
            />}

            <DataTable
              onSelection={(e) => onSelectChecxBoxChange(e)}
              rowSelectionModel={ids}
              page={page - 1}
              totalRecords={totalRecords}
              rowsPerPage={rowsPerPage}
              rows={data}
              columns={columns}
              getRowId={(row) => row.id}
              checkboxSelection={true}
              onPageChange={(e) => handlePageChange(e + 1)}
              onPageSizeChange={(e) => handlePageSize(e)}
              noRecordText={t('ACCOPERALOG007')}
              onColumnVisibilityModelChange={(newVisibilityModel) => {
                setColumnSelected(newVisibilityModel)
              }}
            />
          </Box>
        </>)
          : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={"center"}>{t('ACCOPERALOG008')}</Typography></Box>)
      }
    </>
  );
}

export default OperationLog
