//import { react } from '@babel/types';
import { Autocomplete, Button, Grid, Typography, TextField, makeStyles, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import CustomInput from '../../components/CustomInput';
import RightViewLayout from '../../components/RighViewLayout';
import { postAccessGroup } from '../../services/AccessGroupService';
import { getTimeZoneIntervalList } from '../../services/SchedulesService';
import CommonUtil from '../../util/CommonUtils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from 'antd';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import ViewPage from '../../components/ViewPage';
import Loader from '../../components/Loader';
import { REACT_GROUP_ACCESS_TIME } from '../../constants/ReactEndPoints';

export default function AddGroupAccessTime(props) {
  const { t } = useTranslation()
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [schedules, setSchedules] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const screenWidth = useMediaQuery('(min-width:1510px)')
  const [payload, setPayload] = React.useState({
    id: '',
    name: '',
    schedulerId: '',
    startTime: '',
    endTime: '',
  });
  const [eventDate, setEventDate] = React.useState({
    startTime: '',
    endTime: '',
  });
  const [error, setError] = React.useState({
    name: false,
    schedulerId: false,
    startTime: false,
    endTime: false
  });

  const defaultFilter = {
    id: '',
    name: '',
    schedulerId: '',
    startTime: null,
    endTime: null,
  };

  const resetFilter = () => {
    setPayload({
      ...defaultFilter,
    });
    navigate(REACT_GROUP_ACCESS_TIME);
  };

  useEffect(() => {
    loadSchedules(0, 1000, '');
  }, []);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/group-access-time')
  };

  const [addAccessGroup, setAddAccessGroup] = React.useState(false);

  React.useEffect(() => {
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setPermissions(prev => [...prev, item.code])
        });
      }
      setAddAccessGroup(true);
    });
  }, []);

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime017'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(payload.schedulerId)) {
      setError({
        ...error,
        schedulerId: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime018'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(payload.startTime)) {
      setError({
        ...error,
        startTime: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime019'), { variant: 'error' })
      return;
    }
    if (CommonUtil.isEmptyString(payload.endTime)) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime020'), { variant: 'error' })
      return;
    }
    
    const startDateTime = moment(new Date(payload.startTime));
    const endDateTime = moment(new Date(payload.endTime));

    if (startDateTime.isAfter(endDateTime)) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime038'), { variant: 'error' })
      return;
    }

    // Convert payload.startTime and payload.endTime to moment objects before comparing

    if (moment(payload.startTime).isSame(moment("Fri Jan 01 2038"), "day")) {
      setError({
        ...error,
        startTime: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' });
      return;
    }

    if (moment(payload.endTime).isSame(moment("Fri Jan 01 2038"), "day")) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' });
      return;
    }


    if (startDateTime.isAfter(moment.utc('2038-01-01', 'YYYY-MM-DD'))) {
      setError({
        ...error,
        startTime: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    if (endDateTime.isAfter(moment.utc('2038-01-01', 'YYYY-MM-DD'))) {
      setError({
        ...error,
        endOf: true,
      });
      enqueueSnackbar(t('AccGroupAccessTime039'), { variant: 'error' })
      return;
    }

    // let startdate=payload.startTime.getFullYear()+"-"+payload.startTime.getMonth()+"-"+payload.startTime.getDate()+"T"+payload.startTime.getHours()+":"+payload.startTime.getMinutes()+":00Z";
    // let enddate=payload.endTime.getFullYear()+"-"+payload.endTime.getMonth()+"-"+payload.endTime.getDate()+"T"+payload.endTime.getHours()+":"+payload.endTime.getMinutes()+":00Z";

    const state = {
      name: payload.name,
      schedulerId: payload.schedulerId,
      startTime: convertToISOInUTC(payload.startTime),
      endTime: convertToISOInUTC(payload.endTime)
    };

    if (CommonUtil.isEmptyString(payload.id)) {
      setLoader(true);
      postAccessGroup(state).then((res) => {
        if (res?.data?.code === 'DMSI0000') {
          enqueueSnackbar(t("AccGroupAccessTime021"), { variant: 'success' })
          handleClose();
        } else {
          enqueueSnackbar((res.data.msg), { variant: 'error' })
        }
        setLoader(false);
      });
    }
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().slice(0, -5) + "Z";
    return formattedDate;
  }

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: false,
    });
  };

  const loadSchedules = (page, size, name) => {
    getTimeZoneIntervalList(page + 1, size, name).then((res) => {
      if (!CommonUtil.isEmpty(res) && res?.data?.code === 'DMSI0000'
        && !CommonUtil.isEmpty(res?.data?.data)
        && res?.data?.data?.timeZone instanceof Array) {
        setSchedules(res.data.data.timeZone);
      } else {
        setSchedules([]);
      }
    });
  };

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = permissions.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  const findTimeSlotById = (id) => {
    if (CommonUtil.isEmptyString(id)) {
      return '';
    }
    var timeSlot = { id: '' };
    schedules.map((s) => {
      if (s.id === id) {
        timeSlot = s;
      }
    });
    return timeSlot;
  };

  const disabledEndDate = (current) => {
    const currentv1 = new Date(current);
    const startTime1 = new Date(payload.startTime);
    startTime1.setDate(startTime1.getDate() - 1);
    return current && (currentv1 < startTime1);
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const convertToISOInUTC = (date) => {
    if (date) {
      const offset = (0 >= 0 ? '+' : '-') + Math.abs(0).toString().padStart(2, '0') + ':00';
      return date.format('YYYY-MM-DDTHH:mm:ss') + offset;
    }
  };

  const fieldAccessGroupName = () => (
    <CustomInput
      id="textfield-addaccessgrouptime-name"
      error={error.name}
      name='name'
      label={t('COMMON006')}
      required
      size='small'
      height={{ xl: "64px", xs: "50px" }}
      padding={{ xl: "0px 20px", xs: "0px 10px" }}
      onKeyDown={handleKeyPress}
      placeholder={t('AccGroupAccessTime022')}
      value={payload.name}
      handleChange={(data) => handleChange(data)}
      inputProps={{
        maxLength: 255,
      }}
    />
  )

  const fieldTimeSlot = () => (
    <Autocomplete
      id="dropdown-addaccessgrouptime-timeslot"
      fullWidth
      size='small'
      value={payload.schedulerId ? findTimeSlotById(payload.schedulerId) : null}
      options={schedules ? schedules : []}
      getOptionLabel={(option) => option.operationName}
      renderInput={(params) =>
        <CustomInput
          label={t('AccTime013')}
          required
          height={{ xl: "46px", xs: "33px" }}
          padding={{ xl: "0px 20px", xs: "0px 5px" }}
          handleChange={(e) => console.log()}
          {...params}
          variant='outlined'
          placeholder={t('AccGroupAccessTime023')}
          error={error.schedulerId}
          cursor='pointer'
          onKeyDown={handleKeyPress}
        />}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setPayload({
            ...payload,
            schedulerId: newValue.id,
          });
          setError({
            ...error,
            schedulerId: false
          })
        } else {
          setPayload({
            ...payload,
            schedulerId: '',
          });
        }
      }}
    />
  );

  const disabledStartDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const fieldStartTime = () => (
    <>
      <Typography variant='inputFieldLabel' >
        {t('AccGroupAccessTime004')} <span style={{ color: 'red' }}>*</span>
      </Typography>
      <DatePicker
        showTime
        placeholder={t('AccGroupAccessTime024')}
        value={payload.startTime}
        onKeyDown={handleKeyPress}
        label={t('AccGroupAccessTime004')}
        status={error.startTime ? "error" : ""}
        onChange={(startTime) => {
          setPayload({
            ...payload,
            startTime: startTime,
          });
          setEventDate({
            ...eventDate,
            startTime: startTime?.format("YYYY-MM-DDTHH:mm:ssZ"),
          });
          setError({
            ...error,
            startTime: false
          });
        }}
        // format={yyyy-MM-dd'T'HH:mm:ssXXX"}
        disabledDate={disabledStartDate}
        style={{
          width: '100%',
          height: screenWidth ? "64px" : "50px",
          marginTop: "10px",
          cursor: 'pointer'
        }}
      />
    </>
  );

  const fieldEndDate = () => (
    <>
      <Typography variant='inputFieldLabel' >
        {t('AccGroupAccessTime005')} <span style={{ color: 'red' }}>*</span>
      </Typography>
      <DatePicker
        showTime
        placeholder={t('AccGroupAccessTime025')}
        value={payload.endTime}
        label={t('AccGroupAccessTime005')}
        onKeyDown={handleKeyPress}
        onChange={(endTime) => {
          setPayload({
            ...payload,
            endTime: endTime,
          });
          setEventDate({
            ...eventDate,
            endTime: endTime?.format("YYYY-MM-DDTHH:mm:ssZ"),
          });
          setError({
            ...error,
            endOf: false
          });
        }}
        disabledDate={disabledEndDate}
        status={error.endof ? "error" : ""}
        style={{
          width: '100%',
          marginTop: "10px",
          height: screenWidth ? "64px" : "50px",
          cursor: 'pointer'
        }}
      />
    </>
  )

  return (
    <>
      {
        addAccessGroup ? checkPermission("ADD_EDIT_ACCESS_GROUP") ? (<ViewPage
          title={t("AccGroupAccessTime016")}
          path={true}
          onClick={() => navigate('/group-access-time',
            { state: { pageNumber: location?.state?.pageNumber, pageSize: location?.state?.pageSize } })}
        >
          <Grid container ml={-0.5}>
            <Grid item xs={12} md={6} sx={{ pr: { xl: "30px", md: "20px" } }}>
              {fieldAccessGroupName()}
            </Grid>
            <Grid item xs={12} md={6} >
              {fieldTimeSlot()}
            </Grid>
            <Grid item xs={12} md={6} sx={{ pr: { xl: "30px", md: "20px" } }}>
              {fieldStartTime()}
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xl: "0px", md: "0px", xs: "20px" } }} >
              {fieldEndDate()}
            </Grid>
            <Grid item xs={12}
              sx={{
                mt: { xl: "40px", xs: "30px" },
                display: "flex",
                justifyContent: { lg: "flex-end", xs: "center" }
              }}
            >
              <Button
                id="button-addaccessgrouptime-add"
                onClick={handleSubmit}
                variant='contained'
                sx={{
                  width: { xl: "233px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                  marginRight: "30px"
                }}
              >
                {t('COMMON018')}
              </Button>

              <Button
                id="button-addaccessgrouptime-clear"
                onClick={() => resetFilter()}
                variant='outlined'
                sx={{
                  width: { xl: "233px", xs: "180px" },
                  height: { xl: "64px", xs: "50px" },
                }}
              >
                {t('COMMON009')}
              </Button>
            </Grid>
          </Grid>
          {loader ? <Loader open={loader} /> : <></>}
        </ViewPage>)
          : (<Box marginTop={'150px'} width={'100%'}>
            <Typography textAlign={'center'}>{t("AccReader028")}</Typography>
          </Box>) : <></>
      }
    </>
  );
}
