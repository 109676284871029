import { Box, Button, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/CustomInput';
import { getCredentialByEmpCodeAndCredTypeId, updateDevicePasscode } from '../../../services/DeviceService';
import CommonUtil from '../../../util/CommonUtils';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const BioSpecForPin = (props) => {


    const { enqueueSnackbar } = useSnackbar();
    const [visibility,setVisibility]=useState(true);
    const [state, setState] = useState({
        devId: props.devId,
        personId: props.credential.employeeId,
        passcode: '',
        empCode:props.credential.employeeCode
    });
    const [error, setError] = useState({
        devId: false,
        personId: false,
        passcode: false,
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
        setError({
          [name]: false,
        });
    };

    const handleSubmit= () => {
        if (CommonUtil.isEmptyString(state.passcode)) {
          setError({
            ...error,
            passcode: true,
          });
          enqueueSnackbar('Passcode is required', {
            variant: 'error',
        });
          return;
        }
        let device=[props.pin.deviceDetails.ip,props.credential.firstName,props.credential.lastName]
        updateDevicePasscode(state.devId, state.personId, state.passcode,device, (data) => {
          if (data?.data?.code === 'CMSI0005') {
            enqueueSnackbar(data.data.message, {
                variant: 'success',
            });
            props.onClose();
          }
          else if (data?.data?.code === 'CMSI0004') {
            enqueueSnackbar(data.data.message, {
                variant: 'success',
            });
            props.onClose();
          }
          else{
            enqueueSnackbar(data.data.message, {
                variant: 'error',
            });
            props.onClose(); 
          }
          props.reload();
        });
       
      };

      useEffect(()=>{
        getCredentialByEmpCodeAndCredTypeId(state.empCode,102, (data) => {
          if (data?.data?.code === 'CMSI0000') {
            setState({
              ...state,
              passcode:data.data.data.credential[0].passcode
            })
           
          }
        })
      },[])
    return (
        <>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12} sm={12}>
                    <Box display='flex' justifyContent='center' fullWidth my={1}>
                        <CustomInput
                        id ="textfiled-biospecforpin-passcode"
                            required
                            fullWidth
                            name='passcode'
                            error={error.passcode}
                            label={'Passcode'}
                            helperText=''
                            variant='outlined'
                            type={visibility ? 'password' : 'text'}
                            size='small'
                            handleChange={handleChange}
                            value={state.passcode}
                            inputProps={{
                                maxLength: 10,
                            }}
                            validation="numericWithoutDot"
                            InputProps={{
                              endAdornment: visibility ? (
                                <VisibilityOff
                                  style={{ cursor: 'pointer',opacity:0.6  }}
                                  onClick={() => {
                                    setVisibility(!visibility);
                                  }}
                                />
                              ) : (
                                <Visibility
                                  style={{ cursor: 'pointer',opacity:0.6 }}
                                  onClick={() => {
                                    setVisibility(!visibility);
                                  }}
                                />
                              ),
                            }}
                        />
                    </Box>
                    <Typography><span style={{fontWeight:600}}>{"Tips: "}</span> If device is non-touch screen, it can not use Password verify function</Typography>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} display={'flex'} marginTop={2}>
                    <Button id ="button-biospecforpin-submit" size='large' fullWidth variant='contained'
                        style={{ marginRight: '15px', textTransform: 'none' }}
                    onClick={()=>handleSubmit()}
                    >Submit</Button>
                    <Button id = "button-biospecforpin-cancel" size='large' fullWidth variant='outlined' style={{ textTransform: 'none' }}
                        onClick={() => props.onClose()}
                    >Cancel</Button>
                </Grid>

            </Grid>

        </>
  )
}

export default BioSpecForPin
