
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem, GridFilterAltIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import DataTable from '../../components/layout-components/DataTable';
import RightViewLayout from '../../components/RighViewLayout';
import OverflowTip from '../../components/tooltip';
import { REACT_URL_SITE_VIEW } from '../../constants/ReactEndPoints';
import { loadLocSiteByFilter } from '../../services/SiteService';
import { timeZoneList } from '../../services/TimeZone';
import CommonUtil from '../../util/CommonUtils';
import { SiteFilter } from './SiteFilter';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon} from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon} from '../../assets/FilterIcon.svg';
import { ReactComponent as ViewIcon} from '../../assets/ViewIcon.svg';

function Site() {

    let navigate = useNavigate();

    const [site, setSite] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [page, setPage] = React.useState(location?.state?.pageNumber?location?.state?.pageNumber:1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize?location?.state?.pageSize:10);
    const [open, setOpen] = useState(false);
    const [Filtered, setFiltered] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [load, setLoad] = useState(true);
    const [perViewSite, setViewSite] = useState(true);
    const [perListSite, setListSite] = useState(true);
    const [ids,setIds]=useState([]);
    const[data,setData] = useState([])

    const defaultFilter = {
        name: '',
        ids: ''
    };

    const getTimezoneName = (value) => {
        var name = '';
        timeZoneList.map((t) => {
            if (value == t.value) {
                name = t.name;
            }
        });
        return name;
    };

    const [filterData, setFilterData] = useState({
        ...defaultFilter,
    });

    useEffect(() => {
        var l = [];
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setRoles(l);
            setViewSite(l.includes("ACC_VIEW_SITE_DETAILS")||(api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setListSite(l.includes("ACC_VIEW_SITE_LISTS")||(api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

            if(l.includes("ACC_VIEW_SITE_LISTS")||(api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')){
                loadLocSiteByFilter(page, rowsPerPage, filterData).then((res) => {
                    if (res?.data?.code === 'LMSI0000') {
                        setSite(res.data.data.site);
                        setTotalRecords(res.data.data.totalCount);
                    }
                });
            }
        });

    }, []);



    const [roles, setRoles] = React.useState(['']);

    const checkPermission = (code) => {
        if (api.decoded() != undefined && api.decoded().roleCode === 'OWNER')
            return true;

        return roles.includes(code);
    }

    useEffect(() => {
        var list = [];
        if(site && site.length > 0)
        site.map((item, index) => {
            list.push(
                {
                  "name": item.name,
                  "siteTimeZone": item.address ? getTimezoneName(item.address.timeZone) : '-',
                  "country": item.address ? item.address.country : '-',
                  "provinceState": item.address ? item.address.state : '-',
                  "city": item.address ? item.address.city : '-',
                  "addressLine1": item.address ? item.address.addressLine1 : '-',
                  "id": item.id
                }
              );
        })
      
        setData(list)
    }, [site])
    const columns = [
        {
            field: 'name',
            hideable:false,
            headerName:t('ACCSITE001'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCSITE001')} >
                    <span>
                    {t('ACCSITE001')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'siteTimeZone',
            headerName: t('ACCSITE002'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCSITE002')}>
                    <span>
                    {t('ACCSITE002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.siteTimeZone}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'country',
            headerName: t('ACCDA0024'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCDA0024')}>
                    <span>
                    {t('ACCDA0024')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.country}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'provinceState',
            headerName: t('ACCLANG008'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCLANG008')}>
                    <span>
                    {t('ACCLANG008')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.provinceState}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'city',
            headerName: t('ACCORG020'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG020')}>
                    <span>
                    {t('ACCORG020')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                value={e.row.city}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'addressLine1',
            headerName: t('ACCLANG007'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCLANG007')}>
                    <span>
                    {t('ACCLANG007')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.addressLine1}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        // {
        //     field: 'description',
        //     headerName: t('ACCSITE003'),
        //     flex: 1,
        //     renderHeader: (e) => (
        //         <Tooltip title={t('ACCSITE003')} >
        //             <span>
        //             {t('ACCSITE003')}
        //             </span>
        //         </Tooltip>
        //     ),
        //     renderCell: (e) => (
        //         <OverflowTip
        //             value={e.row.description}
        //             onClick={() => console.info()}
        //             style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 alignItems: 'center',
        //             }}
        //         ></OverflowTip>
        //     ),
        // },
        {
            headerName:t('ACCORG006'),
            hideable:false,
            sortable: false,
            headerClassName: 'no-sort-filter',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG006')} >
                    <span>
                    {t('ACCORG006')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                   {perViewSite &&  <GridActionsCellItem    
                        icon={<Tooltip title={t('ACCSITE004')} >
                            <ViewIcon id={"icon-site-list-view"} style={{ cursor: 'pointer' }} 
                            onClick={() => navigate(REACT_URL_SITE_VIEW+`/${e.row.name}`, { state: { data: e.row,pageNumber:page,pageSize:rowsPerPage}})} />
                        </Tooltip>
                        }
                    />}
                </>
            ),
        },
    ];

    const FieldSiteName = (key) => (
        <CustomInput
        id={"textfield-site-filter-name"}
            placeholder={t('ACCSITE001')}
             
            height={{xl:"50px",xs:"40px"}}
            padding={{xl:"0px 20px",xs:"0px 10px"}}
            value={filterData.name}
            onKeyDown={handleKeyPress}
            size='small'
            fullWidth
            name='name'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 60,
            }}

        />
    );

    const resetFilterData = () => {
        setFilterData({ ...defaultFilter });
        loadData(1, rowsPerPage, defaultFilter);
        setFiltered(false);
    };

    const handleFilterDataChange = () => {
        loadData(1, rowsPerPage, filterData);
        setFiltered(true);
        setLoad(false);
        setPage(1);
        // setOpen(false);
    };

    useEffect(() => {
        if (load && !filterData)
            loadData(page, rowsPerPage, defaultFilter);
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (filterData && !load)
            loadData(1, rowsPerPage, filterData);
    }, [Filtered]);

    const handleChange = (event) => {
        setFiltered(false);
        setLoad(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
        setDisabled(event.target.value === '' ? true : false);
    };
    const DEFAULT_PAGE_SIZE = 10;

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setDisabled(true);
        setFiltered(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    }

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
      };

    const actions = () => (
        <>
            <Box item display={"flex"} >
                <Tooltip title={t('ACCORG008')}>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={2}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <RefreshIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={2}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                <Tooltip title={t('ACCORG009')}>
                    <Box
                        aria-label="filter"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <FilterIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
            </Box>
        </>
    );

    const loadData = (pageNumber, pageSize, filterData) => {
        setIds([]);
        loadLocSiteByFilter(pageNumber, pageSize, filterData).then((res) => {
            if (res?.data?.code === 'LMSI0000') {
                setSite(res.data.data.site);
                setTotalRecords(res.data.data.totalCount);
            }
        });
    };


    const refreshTableData = () => {
        setFilterData({
            ...defaultFilter,
        });
        setPage(1);
        console.log("here");
        console.log(page);
        console.log("here");
        loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleFilterDataChange();
        }
      };

      useEffect(()=>{
        if(!open && !disabled){
            refreshTableData() ;
        }
      },[open]) 


    return (
        <>
            {perListSite ? (<> <RightViewLayout title={t('ACCSITE006')} type='table' actions={actions} navigateBack={true}/>
            <Box sx={{ marginTop:{xl:"30px",xs:"15px"},width:"100%"}}>
                {open && <FilterComponent
                    firstField={FieldSiteName()}
                    clearFilter={clearFilter}
                    handleSubmit={handleFilterDataChange}
                    disabled={disabled}
                />}
                <DataTable
                    page={page - 1}
                    totalRecords={totalRecords}
                    rowsPerPage={rowsPerPage}
                    columns={columns}
                    rows={data}
                    checkboxSelection={true}
                    onSelection={(e) => onSelectChecxBoxChange(e)}
                    rowSelectionModel={ids}
                    // toolbar={filters}
                    onPageChange={(e) => {
                        const CurrentPage = CommonUtil.getPageNumber(e);
                        setPage(CurrentPage);
                        if (Filtered) {
                            loadData(CurrentPage, rowsPerPage, filterData);
                        } else {
                            loadData(CurrentPage, rowsPerPage, defaultFilter);
                        }
                    }}
                    onPageSizeChange={(e) => {
                        setFilterData({ ...defaultFilter });
                        setRowsPerPage(e);
                        setPage(1);
                        loadData(1, e, defaultFilter);
                    }}
                    noRecordText={t('ACCSITE007')} />
           </Box>
            </>) : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t('ACCSITE008')}</Typography></Box>)}
        </>
    );
}

export default Site;