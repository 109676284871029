import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import RightViewLayout from '../../components/RighViewLayout';
import DataTable from '../../components/layout-components/DataTable';
import { getAllDoor } from '../../services/DoorService';
import CommonUtil from '../../util/CommonUtils';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import { REACT_URL_EDIT_DOOR } from '../../constants/ReactEndPoints';
import { useLocation, useNavigate } from 'react-router';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';

const DoorList = () => {
    const { t } = useTranslation()
    const [openFilter, setOpenFilter] = useState(false);
    const location = useLocation();
    const [page, setPage] = React.useState(location?.state?.pageNumber ? location?.state?.pageNumber : 1);
    const [totalCount, setTotalRecords] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize ? location?.state?.pageSize : 10);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [filter, setFilter] = useState(false);
    const [load, setLoad] = useState(true);
    const [perViewDoor, setViewDoor] = useState(true);
    const [perEditDoor, setEditDoor] = useState(true);
    const [perListDoor, setListDoor] = useState(true);
    const [ids, setIds] = useState([]);

    const [change, setChange] = React.useState(false);
    const [disabled, setDisabled] = useState(true);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);

    const defaultDoor = {
        deviceSn: ''
    };
    const [filterData, setFilterData] = useState(defaultDoor);

    useEffect(() => {
        if (load && !filter) {
            getPermissionsByRoleId(api.decoded().roleId).then((res) => {
                var l = [];
                res.data.data.rolePermissions.map((item, index) => {
                    l.push(item.code);
                });
                console.log(l);
                setRoles(l);
                setEditDoor(l.includes("ACC_EDIT_DOOR") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setViewDoor(l.includes("ACC_VIEW_DOOR") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setListDoor(l.includes("ACC_DOOR_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                if (l.includes("ACC_DOOR_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                    loadData(page, rowsPerPage, defaultDoor);
                }
            });
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (filter && !load && reload) {
            loadData(1, rowsPerPage, filterData);
            setReload(false)
        }
    }, [filter, reload]);

    const loadData = (pageNumber, pageSize, filter) => {
        setIds([]);
        const payload = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            filter: filter
        }
        getAllDoor(payload, (res) => {
            if (res?.data?.code === 'DMSI0000' && !CommonUtil.isEmpty(res?.data?.data)) {
                setTotalRecords(res.data.data.totalCount);
                setData(res.data.data.doors);
            } else {
                setTotalRecords(0);
                setData([]);
            }
            setClearAndRefresh(false);
        });
    }

    React.useEffect(() => {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setRoles(l);
            // setEditDoor(checkPermission("ACC_EDIT_DOOR"));
            // setViewDoor(checkPermission("ACC_VIEW_DOOR"));
        });
    }, []);

    const [roles, setRoles] = React.useState(['']);

    const findDefaultTimeSlot = (door) => {
        if (door.activeTimeSegBusinessId === "9999") {
            return '24-Hours General';
        }
        else {
            return door.activeTimeSegBusinessName
        }
    }

    const columns = [
        {
            field: 'doorParameters.name', hideable: false, headerName: t('AccDMTopology011'), tooltip: t('AccDMTopology011'), flex: 1,
            valueGetter: (rowData) => (rowData.row.doorParameters.name)
        },

        { field: 'deviceName', headerName: t('AccDevice014'), tooltip: t('AccDevice014'), flex: 1 },

        // { field: 'deviceSn', headerName: t('AccDoor001'), tooltip: t('AccDoor001'), flex: 1 },

        {
            field: 'doorParameters.doorNo', headerName: t('AccDoor002'), tooltip: t('AccDoor002'), flex: 1,
            valueGetter: (rowData) => (rowData.row.doorParameters.doorNo)
        },

        {
            field: 'doorParameters.enabled', headerName: t('AccDoor003'), tooltip: t('AccDoor003'), flex: 1,
            renderCell: (rowData) => (
                rowData.row.doorParameters.enabled === "true" ? <DoneIcon style={{ color: 'green' }} className='refresh-btn' /> : <ClearIcon style={{ color: 'red' }} className='refresh-btn' />
            ),
        },

        {
            field: 'doorParameters.activeTimeSegBusinessName', headerName: t('AccDoor004'), tooltip: t('AccDoor004'), flex: 1.5,
            valueGetter: (rowData) => (findDefaultTimeSlot(rowData.row.doorParameters))
        },

        {
            field: 'doorParameters.verifyModeName', headerName: t('AccDoor005'), tooltip: t('AccDoor005'), flex: 1.5,
            valueGetter: (rowData) => (rowData.row.doorParameters.verifyModeName)
        },

        {
            field: 'status', headerName: t('AccDevice051'), tooltip: t('AccDevice051'), flex: 1,
            renderCell: (rowData) => (
                <span
                    style={{
                        marginRight: '10px',
                        fontWeight: 'bold',
                        color: rowData.row.status === 1 ? 'green' : 'red',
                    }}
                >
                    {rowData.row.status === 1 ? 'Online' : 'Offline'}
                </span>
            ),
        },

        {
            field: 'actions', hideable: false, headerName: t('ACCORG006'), type: 'actions', flex: 1,
            renderCell: (e) => (
                <>
                    {/* <GridActionsCellItem
                        style={{ width: '30%' }}
                        icon=
                        {perEditDoor ? (<Tooltip title={t('AccDoor006')}><Edit id= "icon-doorlist-edit" width={'17px'} style={{ marginRight: '10px', cursor: 'pointer' }}
                            onClick={(rowData) => { handleEditDoor(e.row) }}
                        /></Tooltip>):<Box></Box>}
                    /> */}
                    <GridActionsCellItem
                        icon={perEditDoor ? <Tooltip title={t('AccDoor006')}>
                            <EditIcon id="icon-auxiliaryoutput-edit"
                                onClick={(rowData) => { handleEditDoor(e.row) }}
                            />
                        </Tooltip> : <></>} />
                </>
            )
        }
    ];

    const handleEditDoor = (id) => {
        navigate(REACT_URL_EDIT_DOOR + `/${id.doorId}`, { state: id, pageNumber: page, pageSize: rowsPerPage })
    }
    const DEFAULT_PAGE_SIZE = 10;
    const handleRefresh = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            setFilterData(defaultDoor);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            loadData(1, DEFAULT_PAGE_SIZE, defaultDoor);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else {
            setFilterData(defaultDoor);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            setPage(1);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
    };

    const actions = () => (
        <Box item display={"flex"} >
            <Tooltip title={t('ACCORG008')}>
                <Box
                    aria-label="refresh"
                    onClick={() => handleRefresh()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <RefreshIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => handleRefresh()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            <Tooltip title={t('ACCORG009')}>
                <Box
                    aria-label="filter"
                    onClick={() => setOpenFilter(!openFilter)}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => setOpenFilter(!openFilter)}
                    sx={{ display: { xl: "none", xs: "block" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
        </Box>
    );
    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
    };

    useEffect(() => {
        if (filterData.deviceSn === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultDoor);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const FieldSerialNumber = (key) => (
        <CustomInput
            id="textfiled-doorlist-device-sn"
            placeholder={t('AccDevice013')}
            value={filterData.deviceSn}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth

            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            name='deviceSn'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 30,
            }}

        />
    );

    const clearFilter = () => {
        setFilterData(defaultDoor);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultDoor);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    useEffect(() => {
        if (!openFilter && !disabled) {
            handleRefresh();
        }
    }, [openFilter])

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            loadData(e, rowsPerPage, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setPage(1);
            loadData(1, e, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setPage(1);
        }
    }

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    return (
        <>
            {perListDoor ? (<> <RightViewLayout
                navigateBack={true}
                title={t("ACCDA0016")}
                actions={actions}
                type='table'
            />
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {openFilter && <FilterComponent
                        firstField={FieldSerialNumber()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <DataTable
                        page={page - 1}
                        totalRecords={totalCount}
                        rowsPerPage={rowsPerPage}
                        rows={data}
                        columns={columns}
                        getRowId={(row) => row.doorId}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        checkboxSelection={true}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t("AccDoor007")}
                    />
                </Box>
            </>) : (<Box marginTop={'15%'} marginLeft={'35%'}><Typography textAlign={'center'}>{t("AccDoor008")}</Typography></Box>)
            }</>
    )
}

export default DoorList
