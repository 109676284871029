import DataTable from '../../components/layout-components/DataTable';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Avatar } from '@mui/material';
import { REACT_URL_PERSON_VIEW } from '../../constants/ReactEndPoints';
import OverflowTip from '../../components/tooltip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import RightViewLayout from '../../components/RighViewLayout';
import React, { useEffect, useState } from "react";
import { loadOrgPersons } from "../../services/PersonService";
import CustomInput from '../../components/CustomInput';
import { FilterComponent } from '../../components/FilterComponent';
import { Grid } from '@mui/material'
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';
import CommonUtil from '../../util/CommonUtils';

function Person() {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [person, setPerson] = useState([]);
    const location = useLocation();
    const [page, setPage] = React.useState(location?.state?.pageNumber ? location?.state?.pageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize ? location?.state?.pageSize : 10);
    const [open, setOpen] = useState(false);
    const [perViewPerson, setViewPerson] = useState(true);
    const [perListPerson, setListPerson] = useState(false);
    const [roles, setRoles] = React.useState([]);
    const [filter, setFilter] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);
    const [ids, setIds] = useState([]);

    const defaultFilter = { firstName: '', lastName: '', code: '' };
    const [filterData, setFilterData] = useState({ ...defaultFilter, });
    useEffect(() => {
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            var l = [];
            res.data.data.rolePermissions.map((item, index) => {
                l.push(item.code);
            });
            setRoles(l);
            // setViewPerson(l.includes("ACC_VIEW_PERSON_PROFILE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            // setListPerson(l.includes("ACC_VIEW_PERSONLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setListPerson(true)
        });
    }, []);

    useEffect(() => {
        if (roles.includes("ACC_VIEW_PERSONLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
            if (load && !filter) {
                loadData(page, rowsPerPage, defaultFilter);
            }
        }
    }, [page, rowsPerPage, roles]);

    useEffect(() => {
        if (filter && !load && reload) {
            loadData(page, rowsPerPage, filterData);
            setReload(false)
        }
    }, [filter, reload]);

    const loadData = (pageNumber, pageSize, filterData) => {
        setIds([]);
        loadOrgPersons(pageNumber, pageSize, filterData).then((res) => {
            if (res?.data?.code === 'OMSI0000') {
                // setPerson(res.data.data.employees);

                var list = [];
                res.data.data.employees.map((item, index) => {
                    list.push(
                        {
                            "id": item.id,
                            "name": item.lastName ? item.firstName + ' ' + item.lastName : item.firstName,
                            "code": item.code,
                            "email": item.email,
                            "phone": item.phone,
                            "profilePhotoURL": item.profilePhotoURL
                        }
                    );
                });
                setPerson(list);
                setTotalRecords(res.data.data.totalCount);
                setClearAndRefresh(false);
            }
            else {
                setPerson([]);
                setTotalRecords(0);
                setClearAndRefresh(false);
            }
        });
    };

    const columns = [
        {
            field: 'name',
            hideable: false,
            headerName: t('ACCORG002'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG002')} >
                    <span>
                        {t('ACCORG002')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ bgcolor: '#C0C0C0', height: '40px', width: '40px', marginRight: '10px' }} alt={e.row.name} src={e.row.profilePhotoURL}></Avatar>
                </OverflowTip>
            ),
        },
        {
            field: 'code',
            headerName: t('ACCORG003'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG003')} >
                    <span>
                        {t('ACCORG003')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.code}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'email',
            headerName: t('ACCORG004'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG004')} >
                    <span>
                        {t('ACCORG004')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.email}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'phone',
            headerName: t('ACCORG005'),
            flex: 2,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG005')} >
                    <span>
                        {t('ACCORG005')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.phone}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            headerName: t('ACCORG006'),
            sortable: false,
            hideable: false,
            headerClassName: 'no-sort-filter',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('ACCORG006')} >
                    <span>
                        {t('ACCORG006')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    {(roles.includes("ACC_VIEW_PERSON_PROFILE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER')
                        || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'))
                        && <Tooltip title={t('ACCORG007')}>
                            <GridActionsCellItem
                                icon={(<ViewIcon id="icon-employee-icon-view" style={{ cursor: 'pointer' }}
                                    onClick={() => navigate(REACT_URL_PERSON_VIEW + `/${e.row.code}`,
                                        { state: { picURL: e.row.profilePhotoURL, pageNumber: page, pageSize: rowsPerPage } })} />)}
                            />
                        </Tooltip>}
                </>
            ),
        },
    ];

    const actions = () => (
        <>
            {perListPerson && <Box item display={"flex"} >
                <Tooltip title={t('ACCORG008')}>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={2}
                        sx={{ display: { xl: "block", xs: "none" } }}
                        id={"refresh-Icon"}
                    >
                        <RefreshIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => refreshTableData()}
                        mr={2}
                        sx={{ display: { xl: "none", xs: "block" } }}
                        id={"refresh-Icon"}
                    >
                        <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                <Tooltip title={t('ACCORG009')}>
                    <Box
                        aria-label="filter"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "block", xs: "none" } }}
                    >
                        <FilterIcon style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box
                        aria-label="refresh"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xl: "none", xs: "block" } }}
                    >
                        <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                    </Box>
                </Tooltip>
                {/* <IconButton arrow aria-label="add" style={{'background':'#76B745','color':'white'}}>
                    <AddIcon fontSize="inherit" />
                </IconButton> */}
            </Box>}

        </>

    );

    const DEFAULT_PAGE_SIZE = 10;

    const refreshTableData = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            setFilterData(defaultFilter);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else {
            setFilterData(defaultFilter);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            setPage(1);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
    };
    //Filter  Related
    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
    };

    useEffect(() => {
        if (filterData.firstName === '' && filterData.lastName === ''
            && filterData.code === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);

        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);

    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true)
    }

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            loadData(e, rowsPerPage, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setPage(1);
            loadData(1, e, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setPage(1);
        }
    }
    const FieldPersonCode = (key) => (
        <CustomInput
            id="textfield-employee-filter-code"
            placeholder={t('COMMON032')}

            value={filterData.code}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='code'
            variant='outlined'
            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            handleChange={handleChange}
            inputProps={{
                maxLength: 20,
            }}

        />
    );
    const FieldFirstName = (key) => (
        <CustomInput
            id="textfield-employee-filter-firstname"
            placeholder={t('COMMON030')}
            value={filterData.firstName}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='firstName'
            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 100,
            }}

        />
    );

    const FieldLastName = (key) => (
        <CustomInput
            id="textfield-employee-filter-lastname"
            placeholder={t('COMMON031')}
            value={filterData.lastName}
            size='large'
            onKeyDown={handleKeyPress}
            fullWidth
            name='lastName'

            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 25,
            }}

        />
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };

    useEffect(() => {
        if (!open && !disabled) {
            refreshTableData();
        }
    }, [open])

    const checkPermission = (permissionCode) => {
        if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
            return true;
        else {
            const permissionIndex = roles.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
    }


    return (
        <>
            {perListPerson ? checkPermission("ACC_VIEW_PERSONLIST") ? <><RightViewLayout title={t('ACCORG001')} actions={actions} navigateBack={true} path={false} />

                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldFirstName()}
                        secondField={FieldLastName()}
                        thirdField={FieldPersonCode()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        getRowId={(row) => row.id}
                        rows={person}
                        // toolbar={filters}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t('ACCORG010')} />


                </Box></> : (
                <Box marginTop={'150px'} width={'100%'}>
                    <Typography textAlign={'center'}>
                        {t("ACCORG024")}
                    </Typography>
                </Box>) : <></>}
        </>
    );
}

export default Person;
