import { Box } from "@material-ui/core";
import { Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import CustomInput from "../../components/CustomInput";
import SimpleDilogBox from "../../components/DilogBox";
import CommonUtil from "../../util/CommonUtils";
import Continue from "../../components/ContinueationDilog";
import CustomizedAccordions from "./CustomizedAccordion";
import { useNavigate } from "react-router-dom";
import {
  getPermissionsByRoleId,
  roleList,
  updateRole,
} from "../../services/UserRolesService";
import { defaultPermissionCode, parentAccess, parentdashboard, parentDevice, parentOrg, parentSite } from "./defaultPermissionCode";
import { getPermissionsList } from "../../services/UserRolesService";
import RightViewLayout from "../../components/RighViewLayout";

import api from '../../configurations/http-common';
import { REACT_URL_PAGE_NOT_FOUND, REACT_URL_USERROLES } from "../../constants/ReactEndPoints";
import PageNotFound from "../../components/PageNotFound";
import Loader from "../../components/Loader";
import CustomDialogBox from "../../components/CustomDialogBox";
export default function EditRolePermission() {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDilog, setOpenDilog] = useState(false);
  const [error, setError] = useState({ name: "", code: "" });
  const [permissionList, setPermissionList] = useState({});
  const [clearAll, setClearAll] = useState(false);
  const [data, setData] = useState({});
  const [state, setState] = React.useState({
    id: "",
    name: "",
    code: "",
    description: "",
    scope: "CUSTOM",
    permissionId: [],
  });
  let navigate = useNavigate();
  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const defaultPermissions = useState(defaultPermissionCode);
  const { roleCode } = useParams();
  const [assignPermission, setAssignPermission] = useState([])
  const [viewErrorPage, setViewErrorPage] = useState(false)
  const [loader, setLoader] = React.useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const [perUserRole, setUserRole] = useState(true);

  const loadUserDetails = () => {
    const payload1 = {
      name: "",
      code: (roleCode).toLowerCase() === 'super admin' ? 'SUPERADMIN' : roleCode,
    }
    roleList(payload1).then((res) => {
      if (res?.data?.code === 'UASI0000') {
        if (res.data.data.totalCount > 0)
          setState(res.data.data.roles[0]);
        else {
          setViewErrorPage(true);
          setState({
            name: "",
            code: ""
          });

        }
      } else {
        setState({
          name: "",
          code: ""
        })
        setViewErrorPage(true);
      }
    });
  }

  useEffect(() => {
    if (state.id !== "")
      getPermissionsByRoleId(state.id).then((res) => {
        if (res?.data?.code === "CACCZE001") {
          setData(res.data.data.rolePermissions);
        }
      });
  }, [state])

  useEffect(() => {
    if (CommonUtil.isEmpty(roleCode)) {
      navigate({
        pathname: REACT_URL_PAGE_NOT_FOUND,
      });
    }
    getPermissionsByRoleId(api.decoded().roleId).then((res) => {
      if (res.data.code === "CACCZE001") {
        res.data.data.rolePermissions.map((item, index) => {
          setAssignPermission(prev => [...prev, item.code])
        });
      }
      setUserRole(true);
    });
  }, []);

  useEffect(() => {
    if (checkPermission("EDIT_USERROLE")) {
      loadUserDetails();
    }
  }, [assignPermission])

  const checkPermission = (permissionCode) => {
    if (api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
      return true;
    else {
      const permissionIndex = assignPermission.filter(x => x === permissionCode);
      return !CommonUtil.isEmpty(permissionIndex);
    }
  }

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      var newPermissions = { ...permissions };
      data.map((data) => {
        newPermissions[data.code] = true;
      });
      setPermissions({
        ...permissions,
        ...newPermissions,
      });
    } else {
      setPermissions({
        ...defaultPermissions,
      });
    }
  }, [data]);

  useEffect(() => {
    setLoader(true);
    getPermissionsList().then((res) => {
      if (res?.data?.code === "CACCZE001") {
        setPermissionList(res.data.data);
      }
      setLoader(false);
    });
  }, []);

  const checkingParent = (parentList, parentCode) => {
    let parentId = ""
    let value = parentList?.find(acc => permissions[acc]);
    if (!CommonUtil.isEmpty(value)) {
      let val = permissionList.filter((p) => parentCode == p.code);
      parentId = val[0]?.id
    }
    return parentId;
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({
        ...error,
        name: t("Please Enter the role Name"),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({
        ...error,
        code: t("Please Enter the role code"),
      });
      return false;
    }

    var newPermissions = new Set();
    if (!permissionList.isEmpty) {
      permissionList.map((p) => {
        if (permissions[p.code]) {
          newPermissions.add(p.id);
        }
      });
    }
    const pDevice = checkingParent(parentDevice, "DEVICE_MANAGEMENT");
    newPermissions.add(pDevice);
    const pAcc = checkingParent(parentAccess, "ACCESS_CONTROL");
    newPermissions.add(pAcc);
    const pDashboare = checkingParent(parentdashboard, "ACC_DASHBOARD");
    newPermissions.add(pDashboare);
    const pSite = checkingParent(parentSite, "SITE_MANAGMENT_DETAILS");
    newPermissions.add(pSite);
    const pOrg = checkingParent(parentOrg, "ACC_ORGANIZATION");
    newPermissions.add(pOrg);
    newPermissions.delete("")
    const payload = {
      ...state,
      permissionId: [...newPermissions],
    };

    if (!CommonUtil.isEmpty(payload)) {

      if (newPermissions.length <= 0) {
        setOpenDilog(true);
      } else {

        setLoader(true);
        updateRole(payload)
          .then((res) => {
            if (res?.data?.code === "UASI0046") {
              enqueueSnackbar(t('ACCRolPerm060'), {
                variant: "success",
              });
              navigate({ pathname: "/roles", state });
            } else if (res?.data?.code === "UASW0004") {
              enqueueSnackbar(t('ACCRolPerm061'),
                {
                  variant: "success",
                }
              );
              navigate({ pathname: "/roles", state });
            } else {
              if (res?.data?.code === "UASE0130")
                setError({ code: res.data.message });
              if (res?.data?.code === "UASE0030")
                enqueueSnackbar(t('ACCRolPerm062'), {
                  variant: "error",
                });
            }
            setLoader(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setOpenDilog(!openDilog);
    }
  };
  const handleDailogSubmit = () => {
    const payload = {
      ...state,
      permissionId: []
    }
    updateRole(payload)
      .then((res) => {
        if (res?.data?.code === "UASI0046") {
          enqueueSnackbar("Role and its Permission Updated Successfully", {
            variant: "success",
          });
          navigate({ pathname: "/roles", state: { state: res.data } });
        } else if (res?.data?.code === "UASW0004") {
          enqueueSnackbar(
            "Role permissions Updated succesfully, but one more not updated.",
            {
              variant: "success",
            }
          );
          navigate({ pathname: "/roles", state });
        } else {
          if (res?.data?.code === "UASE0130")
            setError({ code: res.data.message });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleClearAll = () => {
    setPermissions(!clearAll);
    getPermissionsByRoleId(state.id).then((res) => {
      if (res?.data?.code === "CACCZE001") {
        setData(res.data.data.rolePermissions);
      }
    });
  };
  const setOpenDilogBox = (val) => {
    setOpenDilog(val);
  };
  const handleSetPermission = (v) => {
    setPermissions({
      ...permissions,
      ...v,
    });
  };


  const handleKeyPress1 = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress1);
    return () => {
      window.removeEventListener('keydown', handleKeyPress1);
    };
  }, []);

  const headerStyle = {
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'rgba(71, 75, 79, 0.8)',
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Roboto",
  }


  return (
    <>
      {viewErrorPage ? <PageNotFound /> : perUserRole ? checkPermission("EDIT_USERROLE") ?
        <>
          <RightViewLayout title={t('ACCRolPerm006')} />
          <Box sx={{ marginTop: { xl: "50px", xs: "10px" }, width: "100%", }}>
            <Box>
              <Typography sx={headerStyle}>
                {t('ACCRolPerm007')}
              </Typography>
            </Box>
            <Grid container
              sx={{
                borderRadius: '8px',
                padding: { xl: "30px 30px 0px 30px", xs: "20px 20px 0px 20px" },
                border: "1px solid #DDDDDD",
                mt: "20px",
                '&:hover': {
                  boxShadow: "2px 0px 4px #0000001A, -2px 0px 4px #0000001A, 0px 2px 4px #0000001A, 0px -2px 4px #0000001A",
                  cursor: 'pointer',
                  borderRadius: "8px",
                },
              }}
            >
              <Grid item md={3} sm={6} mr={"30px"}>
                <CustomInput
                  id={"textfield-userrole-update-rolename"}
                  required
                  disabled
                  autoComplete="off"
                  fullWidth
                  error={error.name}
                  name="name"
                  label={"User Role Name"}
                  value={state.name ? state.name : ""}
                  variant="outlined"
                  handleChange={handleChange}
                  helperText={error.name}
                  size='small'
                  height={{ xl: "64px", xs: "50px" }}
                  padding={{ xl: "0px 20px", xs: "0px 10px" }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  validation={"alpha-numeric-space"}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <CustomInput
                  id={"textfield-userrole-update-rolecode"}
                  disabled
                  required
                  autoComplete="off"
                  fullWidth
                  error={error.code}
                  name="code"
                  label={"User Role Code"}
                  value={state.code ? state.code : ""}
                  variant="outlined"
                  size='small'
                  height={{ xl: "64px", xs: "50px" }}
                  padding={{ xl: "0px 20px", xs: "0px 10px" }}
                  handleChange={handleChange}
                  helperText={error.code}
                  inputProps={{
                    maxLength: 50,
                  }}
                  validation={"alpha-numeric-underscore"}
                />
              </Grid>
            </Grid>
            <Box item paddingBottom={2} paddingTop={3}>
              <Typography sx={headerStyle}>
                {t('ACCRolPerm011')}
              </Typography>
              {/* <Typography >
            <b>{t('COMMON010')}</b> {t('ACCRolPerm012')}
          </Typography> */}
              <CustomizedAccordions
                permissions={permissions}
                handleSubmit={() => handleSubmit()}
                setPermissions={(v) => handleSetPermission(v)}
                disabled={false}
              />
            </Box>
            <Grid
              container
              lg={12}
              xs={12}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              p={1}
              paddingRight={3}
              style={{ margin: "12px", padding: "12px 0px", width: "120%" }}
            >
              <Box display="flex" p={1}>
                <Button
                  id={"button-userrole-update-save"}
                  variant='contained'
                  onClick={() => handleSubmit()}
                  sx={{
                    width: { xl: "233px", xs: "180px" },
                    height: { xl: "64px", xs: "50px" },
                    marginRight: "30px"
                  }}
                >
                  {t('COMMON023')}
                </Button>
                <Button
                  id={"button-userrole-update-clear"}
                  onClick={() => handleClearAll()}
                  variant='outlined'
                  sx={{
                    width: { xl: "233px", xs: "180px" },
                    height: { xl: "64px", xs: "50px" },
                  }}
                >
                  {t('COMMON015')}
                </Button>
              </Box>
            </Grid></Box>
          {loader ? <Loader open={loader} /> : <></>}
        </> : (<Box marginTop={'150px'} width={'100%'}>
          <Typography textAlign={'center'}>{t('ACCRolPerm058')} </Typography></Box>) : <></>}
      <CustomDialogBox
        id="deleteDevcie"
        open={openDilog}
        customWidth={'420px'}
        title={t("ACCRolPerm067")}
        message={t("ACCRolPerm066")}
        saveButtonName={t("COMMON016")}
        cancelButtonName={t("COMMON009")}
        onClose={() => setOpenDilogBox(false)}
        handleSubmit={() => handleDailogSubmit()}
      />
    </>
  );
}
