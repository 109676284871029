import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Close } from '../assets/new-images/close icon.svg';
import { useEffect } from 'react';

const CustomDialogBox = (props) => {
   
    const styleDialog = {
        '& .MuiDialog-paper': {
            position: 'absolute',
            boxShadow: 1,
            width: { xs: '360px', xl: props.customWidth ? props.customWidth : '50%' },
            borderRadius: '10px',
            padding: {xl:"30px",xs:"20px"},
            // height:(""+props.message).length<38?{xl:"225px",xs:"180px"}:{xl:"250px",xs:"200px"},
            // top: props.customWidth ? 'none' : '4rem',
        },
    };

    const headerStyle = {
        letterSpacing: '0px',
        color: 'rgba(71, 75, 79, 0.8)',
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "23px",
        fontFamily: "Roboto",
    }

    const  fieldValueLap={
        color: "rgba(71, 75, 79, 0.8)",
        letterSpacing: "0px",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: {xl:"16px",xs:"14px"},
        lineHeight:  {xl:"22px",xs:"20px"},
        fontFamily: "Roboto",
      }

    const handleKeyPress1 = (event) => {
        if (event.key === 'Enter') {
            props.handleSubmit();
        }
      };

      useEffect(() => {
        window.addEventListener('keydown', handleKeyPress1);
        return () => {
          window.removeEventListener('keydown', handleKeyPress1);
        };
      }, [handleKeyPress1]);

    return (
        <Dialog sx={styleDialog} open={props.open} onClose={() => props.onClose()}>
            <Box>
                <Box display='flex' justifyContent={'space-between'}>
                    <Typography 
                        sx={headerStyle}>
                        {props.title}
                    </Typography>
                    <Box onClick={props.onClose} sx={{cursor:'pointer'}} >
                        <Close />
                    </Box>
                </Box>
                <Grid
                sx={{ 
                    marginTop:{xl:"25px",xs:"20px"} 
                }}
                >
                    <Typography sx={fieldValueLap}>{props.message} </Typography>
                    {props.note} 
                    <Grid 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: {xl:'space-between',xs:"center"}, 
                        marginTop:{xl:"30px",xs:"20px"} 
                    }}>
                        <Button
                            id="button-confirm-modal-delete"
                            variant='contained'
                            onClick={() => props.handleSubmit()}
                            sx={{
                                width:{ xl:'165px',xs:"150px"},
                                height:{xl:'64px',xs:"50px"},
                                marginRight:{xl:"0px",xs:"20px"}
                            }}
                        >
                            {props.saveButtonName}
                        </Button>
                        <Button
                            id="button-confirm-modal-cancel"
                            onClick={() => props.onClose()}
                            variant='outlined'
                            sx={{
                                width:{ xl:'165px',xs:"150px"},
                                height:{xl:'64px',xs:"50px"},
                            }}
                        >
                            {props.cancelButtonName}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}

export default CustomDialogBox
