import { Avatar, Box, Grid, Paper, Typography } from '@mui/material';
import React from "react";
import RightViewLayout from '../../components/RighViewLayout';
import CustomInput from '../../components/ViewText';
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadLocZone, loadLocSiteByIds } from '../../services/ZoneService';
import src from '../../assets/images/zoneIcon.svg';
import { timeZoneList } from '../../services/TimeZone';
import CommonUtil from '../../util/CommonUtils';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { useTranslation } from 'react-i18next';
import ViewPage from '../../components/ViewPage';
import { REACT_URL_PAGE_NOT_FOUND } from '../../constants/ReactEndPoints';
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';


const ViewZone = () => {
    const location = useLocation();
    const [zone, setZone] = useState([]);
    const [site, setSite] = useState([]);
    const [address, setAddress] = useState([]);
    const navigate=useNavigate();
    const [viewZone,setViewZone]=useState(false);
    const [viewErrorPage,setViewErrorPage]=useState(false)
    const [permissions,setPermissions]= useState([]);
    const { t } = useTranslation();
    const {zoneName} = useParams();
    const[loader,setLoader]=React.useState(true);

    const defaultFilter = {
        name: zoneName,
        ids: ""
    };

    const getTimeZoneName = (value) => {
        var name = '';
        timeZoneList.map((t) => {
            if (value == t.value) {
                name = t.name;
            }
        });
        return name;
    }

    React.useEffect(() => {

        if (CommonUtil.isEmpty(zoneName)) {
            navigate({
                pathname: REACT_URL_PAGE_NOT_FOUND,
            });
        }
      
        getPermissionsByRoleId(api.decoded().roleId).then((res) => {
            if(res.data.code==="CACCZE001"){
                res.data.data.rolePermissions.map((item, index) => {
                    setPermissions(prev=>[...prev,item.code])
                });
            }
            setViewZone(true);
        });
    }, []);

    useEffect(()=>{
     if(checkPermission("ACC_VIEW_ZONE")){
        loadLocZone(1, 10, defaultFilter).then((res) => {
            setLoader(true);
            if (res?.data?.code === 'LMSI6000') {
                if(!CommonUtil.isEmpty(res.data.data.zones)){
                    setZone(res.data.data.zones[0]);
                    const siteIds = res.data.data.zones.map((site, index) => {
                        return site.siteId;
                    });
                    loadLocSiteByIds(1, 1000, { ids: siteIds }).then((res) => {
                        if (res?.data?.code === 'LMSI0000') {
                            setSite(res.data.data.site[0]);
                            setAddress(res.data.data.site[0].address);
                        }
                    });
                }else{
                    setViewErrorPage(true);
                }
                setLoader(false);
            }
        });
     }
    },[permissions])

    const checkPermission = (permissionCode) => {  
        if(api.decoded().roleCode === 'OWNER' || api.decoded().roleCode === 'SUPERADMIN')
          return true;
        else{
            const permissionIndex = permissions.filter(x => x === permissionCode);
            return !CommonUtil.isEmpty(permissionIndex);
        }
      }

    return (
        <>
            {viewErrorPage?<PageNotFound></PageNotFound>:viewZone ?checkPermission("ACC_VIEW_ZONE") ? <ViewPage title={t('ACCRolPerm024')} 
            path={true}
             onClick={()=>navigate(
                "/zone",
                { state: {pageNumber:location?.state?.pageNumber,pageSize:location?.state?.pageSize}})}>

                    <Box marginBottom={'20px'}>
                        <Avatar alt="View Zone Profile" src={src} sx={{ width: 150, height: 150 }} />
                    </Box>
                    {loader?<Loader open={loader}/>:<></>}

                    <Grid direction='row' alignItems={'flex-start'}  >

                        {CustomInput({ "label": t('ACCZONE001'), "value": zone.name,index:1 })}
                        {CustomInput({ "label": t('ACCDA0021'), "value": site.name,index:2 })}
                        {CustomInput({ "label": t('ACCSITE002'), "value": getTimeZoneName(address.timeZone),index:3 })}
                        {CustomInput({ "label": t('ACCDA0024'), "value": address.country,index:4 })}
                        {CustomInput({ "label": t('ACCORG019'), "value": address.state,index:5 })}
                        {CustomInput({ "label": t('ACCORG020'), "value": address.city,index:6 })}
                        {CustomInput({ "label": t('ACCORG021'), "value": address.addressLine1,index:7 })}
                        {CustomInput({ "label": t('ACCORG022'), "value": address.addressLine2 ,index:8})}
                    </Grid>

            </ViewPage>:(<Box marginTop={'150px'} width={'100%'}>
                <Typography textAlign={'center'}>
                    {t("ACCZONE004")}
                </Typography>
            </Box>):<></>}


        </>

    )
};

export default ViewZone;