import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SidebarAccess from "./SidebarAccess";
import Header from "./Header";
import SecuredRoute from "../../SecuredRoute";
import CommonUtil from "../../util/CommonUtils";
import { BasicConfigurationApiForZlinkRedirect } from "../../services/OnboardService";
import { AppStore, Value } from "../../AppStore";

export default function MainLayout(props) {

  const [validSession, setValidSession] = useState(false);
  const [quickSetupPayload,setQuickSetupPayload]=useState({
    accessGroup:{
      name:"",
      schedulerId: "",
      startTime: "",
      endTime:""
    },
    personIds:[],
    doorIds:[]
  });
  const [errorQuickSetupPayload,setErrorQuickSetupPayload]=useState({
    accessGroup:{
      name:"",
      schedulerId: "",
      startTime: "",
      endTime:""
    },
    personIds:'',
    doorIds:''
  })
  const [data, setData] = useState();

  useEffect(() => {
    const jwtToken = CommonUtil.getCookie("zlink_token");

    if (jwtToken) {
      setValidSession(true);
    } else {
      BasicConfigurationApiForZlinkRedirect().then((data) => {
        if (data?.data?.code === "CAAI0001") {
          setData(data?.data?.data?.localUrl);
          window.location.href = data?.data?.data?.zlinkRedirectUrl;
        }
      });
    }
  }, []);
  
  const value={
      quickSetupPayload,
      setQuickSetupPayload,
      errorQuickSetupPayload,
      setErrorQuickSetupPayload
  }

  return validSession ? (
    <AppStore.Provider value={value}>
    <Box style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <SecuredRoute />
      <Header />
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <SidebarAccess/>

        <Grid
          container
          sx={{ height: `calc(100% - 70px)`, 
             overflowY: "auto", 
             width:"100%",
             padding:{xl:"30px",xs:"15px"}
            }}
          alignContent={"flex-start"}
        >
          {props.children}
        </Grid>
      </Box>
    </Box>
    </AppStore.Provider>
  ) : (
    <></>
  );
}
