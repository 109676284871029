import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CommonUtil from '../../util/CommonUtils';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { loadEventsByHours } from '../../services/EventService';

export default function EventGraph() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [series, setSeries] = useState([
    {
      name: t('ACCDA0003'),
      data: [],
    },
    {
      name: t('ACCDA0004'),
      data: [],
    },
    {
      name: t('ACCDA0005'),
      data: [],
    },
  ]);

  useEffect(() => {
    const translatedSeries = [
      { name: t('ACCDA0003'), data: series[0].data },
      { name: t('ACCDA0004'), data: series[1].data },
      { name: t('ACCDA0005'), data: series[2].data },
    ];
    setSeries(translatedSeries);
  }, [i18n.language]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'chartArea',
      toolbar: {
        show: false,
        autoSelected: 'none',
      },
      zoom: {
        enabled: true,
      },
    },
    legend: {
      horizontalAlign: 'right',
      position: 'top',
      fontSize: '14px', 
      markers: {
        height: '15px',
        width: '15px', 
        offsetY: 2,
        offsetX: -3, 

      },
      
      itemMargin: {
        horizontal: 10, 
      },
    },

    xaxis: {
      type: 'time',
      categories: [],
    },
    yaxis: {
      title: {
        text: t('ACCDA0009'),
      },
    },
    xaxis: {
      title: {
        text: t('ACCDA0006'),
      },
    },
    dataLabels: {
      enabled: false,
    },

    tooltip: {
      x: {
        formatter: function (val) {
          return t('ACCDA0007') + ' : ' + val;
        },
      },

      y: {
        formatter: function (val) {
          return t('ACCDA0007') + ' : ' + val;
        },
      },
    },
    colors: ['#76B745', '#F1AC24', '#F66669'],
  });

  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        title: {
          text: t('ACCDA0006'),
        },
      },
      yaxis: {
        ...prevOptions.yaxis,
        title: {
          text: t('ACCDA0009'),
        },
      },
    }));
  }, [i18n.language]);

  const [timeRange, setTimeRange] = useState(2);
  useEffect(() => {
    var normal = [];
    var alarm = [];
    var warning = [];
    var categories = [];

    loadEventsByHours(timeRange).then((res) => {
      if(res!=null)
      {
      res.data.data.map((event) => {
        if (!CommonUtil.isEmpty(event)) {
          categories.push(event.time);
          normal.push(event.normal);
          alarm.push(event.error);
          warning.push(event.warn);
        }
      });}
      setSeries([
        {
          name: t('ACCDA0003'),
          data: normal,
        },
        {
          name: t('ACCDA0004'),
          data: warning,
        },
        {
          name: t('ACCDA0005'),
          data: alarm,
        },
      ]); 
      setOptions({
        ...options,
        xaxis: {
          type: t('ACCDA0007'),
          categories: categories,
        },
      });
    });
  }, [timeRange]);
  return (
    <div>
      <Box display='flex' flexDirection='row' marginBottom={3}>
        <Box flexGrow={1}>
          <Typography gutterBottom variant='h6' component='h6' style={{ lineHeight:'24px',color: '#474B4F', marginTop: '15px' }}>
            {t('ACCDA0002')}
          </Typography>
        </Box>
        <Box p={1} width='150px' sx={{ 
             padding: '8px',
             width: '220px',
             height: '50px',
             marginRight: '-8px',
            '& .MuiInputLabel-root': {
            display: 'none', // Hide the input label
             },
            '& .MuiSelect-root': {
             paddingTop: '16px', // Adjust the top padding for better alignment
             },
            }}>
          <FormControl variant='outlined' size='small' fullWidth>
          <Select
            value={timeRange}
            id="drop-down-dashboard-event-timerange"
            onChange={(e) => setTimeRange(e.target.value)}
            style={{ lineHeight:'21px', cursor:'pointer'}}
            >
            <MenuItem value={2}>{t('ACCDA0010')}</MenuItem>
            <MenuItem value={4}>{t('ACCDA0011')}</MenuItem>
            <MenuItem value={8}>{t('ACCDA0012')}</MenuItem>
            <MenuItem value={12}>{t('ACCDA0013')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box style={{borderRadius:'8px',
      border: '1px solid #DDDDDD', height:'380px', marginBottom:'30px',
}} >
      <ReactApexChart
        options={options}
        series={series} 
        type='area' height={350} 
        style={{
          borderRadius: '8px',
          marginBottom: '30px',
          marginTop: '10px',
        }} />
</Box>
    </div>
  );
}
