import DataTable from '../../components/layout-components/DataTable';
import { Grid, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem, GridFilterAltIcon } from '@mui/x-data-grid';

import OverflowTip from '../../components/tooltip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RightViewLayout from '../../components/RighViewLayout';
import React, { useEffect, useState } from "react";
import { loadDeviceAuxiliaryOutput } from '../../services/AuxiliaryOutputService';
import { REACT_URL_AUXILIARY_OUTPUT_EDIT, REACT_URL_AUXILIARY_OUTPUT_VIEW } from '../../constants/ReactEndPoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissionsByRoleId } from '../../services/RoleService';
import api from '../../configurations/http-common';
import { FilterComponent } from '../../components/FilterComponent';
import CustomInput from '../../components/CustomInput';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from '../../assets/Refresh.svg';
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg';
import { ReactComponent as ViewIcon } from '../../assets/ViewIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';

function AuxiliaryOutput() {

    const { t } = useTranslation()
    let navigate = useNavigate();
    const [auxOut, setAuxOut] = useState([]);
    const location = useLocation();
    const [page, setPage] = React.useState(location?.state?.pageNumber ? location?.state?.pageNumber : 1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(location?.state?.pageSize ? location?.state?.pageSize : 10);
    const [perViewAuxOUT, setViewAuxOut] = useState(true);
    const [perEditAuxOUT, setEditAuxOut] = useState(true);
    const [perListAuxOUT, setListAuxOut] = useState(true);
    const [ids, setIds] = useState([]);


    const [open, setOpen] = useState(false);

    const [filter, setFilter] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [change, setChange] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [clearAndRefresh, setClearAndRefresh] = useState(false);

    const defaultFilter = { doorName: '', deviceAlias: '', name: '' };
    const [filterData, setFilterData] = useState({ ...defaultFilter, });

    React.useEffect(() => {
        if (load && !filter) {
            getPermissionsByRoleId(api.decoded().roleId).then((res) => {
                var l = [];
                res.data.data.rolePermissions.map((item, index) => {
                    l.push(item.code);
                });
                setRoles(l);
                setViewAuxOut(l.includes("ACC_AUXILIARY__OUTPUT_VIEW") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setEditAuxOut(l.includes("ACC_AUXILIARY_OUTPUT_EDIT") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
                setListAuxOut(l.includes("ACC_AUXILIARY__OUTPUT_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

                if (l.includes("ACC_AUXILIARY__OUTPUT_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
                    loadData(page, rowsPerPage, defaultFilter);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (filter && !load && reload) {
            loadData(1, rowsPerPage, filterData);
            setReload(false)
        }
    }, [filter, reload]);


    const [roles, setRoles] = React.useState(['']);

    const checkPermission = (code) => {
        if (api.decoded() != undefined && api.decoded().roleCode === 'OWNER')
            return true;

        return roles.includes(code);
    }


    const columns = [
        {
            field: 'name',
            hideable: false,
            headerName: t('AccAuxOut001'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccAuxOut001')} >
                    <span>
                        {t('AccAuxOut001')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.name}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'device',
            headerName: t('AccDevice014'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDevice014')} >
                    <span>
                        {t("AccDevice014")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceName}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'sn',
            headerName: t('AccDoor001'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccDoor001')} >
                    <span>
                        {t("AccDoor001")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.deviceSn}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'number',
            headerName: t('AccAuxOut002'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccAuxOut002')} >
                    <span>
                        {t("AccAuxOut002")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.aux_no}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'printerNumber',
            headerName: t('AccDMTopology009'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('AccAuxIn014')} >
                    <span>
                        {t('AccAuxIn014')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.printer_number}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },
        {
            field: 'remarks',
            headerName: t('COMMON007'),
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('COMMON007')} >
                    <span>
                        {t('COMMON007')}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <OverflowTip
                    value={e.row.remark}
                    onClick={() => console.info()}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                ></OverflowTip>
            ),
        },

        {
            headerName: t('COMMON019'),
            hideable: false,
            sortable: false,
            // headerAlign: 'center',
            // align: 'center',
            flex: 1,
            renderHeader: (e) => (
                <Tooltip title={t('COMMON019')} >
                    <span>
                        {t("COMMON019")}
                    </span>
                </Tooltip>
            ),
            renderCell: (e) => (
                <>
                    {perViewAuxOUT && (<GridActionsCellItem
                        sx={{ marginRight: { xl: "20px", xs: "10px" } }}
                        icon={<Tooltip title={t('AccAuxOut003')}>
                            <ViewIcon id="icon-auxiliaryoutput-visibility"
                                onClick={() => navigate(REACT_URL_AUXILIARY_OUTPUT_VIEW + `/${e.row.aux_id}`, { state: { id: e.row.aux_id, pageNumber: page, pageSize: rowsPerPage } })} />
                        </Tooltip>} />)}
                    {perEditAuxOUT && (<GridActionsCellItem
                        icon={<Tooltip title={t('AccAuxOut004')}>
                            <EditIcon id="icon-auxiliaryoutput-edit"
                                onClick={() => navigate(REACT_URL_AUXILIARY_OUTPUT_EDIT + `/${e.row.aux_id}`, { state: { aux: e.row, pageNumber: page, pageSize: rowsPerPage } })} />
                        </Tooltip>} />)}

                </>
            ),
        },
    ];

    const loadData = (pageNumber, pageSize, filter) => {
        setIds([]);
        loadDeviceAuxiliaryOutput(pageNumber, pageSize, filter).then((res) => {
            if (res?.data?.code === 'DMSI0000') {
                setAuxOut(res.data.data.doors);
                setTotalRecords(res.data.data.totalCount);
            } else {
                setAuxOut([]);
                setTotalRecords(0)
            }
            setClearAndRefresh(false);
        });
    };

    const DEFAULT_PAGE_SIZE = 10;

    const refreshTableData = () => {
        setClearAndRefresh(true);
        if (page === 1) {
            setFilterData(defaultFilter);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else {
            setFilterData(defaultFilter);
            setDisabled(true)
            setFilter(false);
            setLoad(true);
            setPage(1);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
    };
    //Filter  Related
    const handleChange = (event) => {
        setChange(true);
        const name = event.target.name;
        setFilterData({
            ...filterData,
            [name]: event.target.value
        });
    };

    useEffect(() => {
        if (filterData.name === '' && filterData.doorName === ''
            && filterData.deviceAlias === '') {
            setDisabled(true);
            if (change) {
                setFilter(false); setLoad(true);
                if (page === 1 && !clearAndRefresh) loadData(1, rowsPerPage, defaultFilter);
                else {
                    if (!clearAndRefresh)
                        setPage(1);
                }
            }
        } else setDisabled(false);
    }, [filterData]);

    const clearFilter = () => {
        setFilterData(defaultFilter);
        setClearAndRefresh(true)
        setDisabled(true);
        setFilter(false);
        setLoad(true);
        if (page === 1) {
            loadData(1, DEFAULT_PAGE_SIZE, defaultFilter);
            setRowsPerPage(DEFAULT_PAGE_SIZE);
        }
        else setPage(1);
        setRowsPerPage(DEFAULT_PAGE_SIZE);
    }

    const handleSubmitFilter = () => {
        setFilter(true);
        setLoad(false);
        setReload(true)
    }


    const actions = () => (
        <Box item display={"flex"} >
            <Tooltip title={t('ACCORG008')}>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"refresh-Icon"}
                >
                    <RefreshIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => refreshTableData()}
                    mr={2}
                    sx={{ display: { xl: "none", xs: "block" } }}
                >
                    <RefreshIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
            <Tooltip title={t('ACCORG009')}>
                <Box
                    aria-label="filter"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "block", xs: "none" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon style={{ cursor: 'pointer' }} />
                </Box>
                <Box
                    aria-label="refresh"
                    onClick={() => setOpen(!open)}
                    sx={{ display: { xl: "none", xs: "block" } }}
                    id={"filter-Icon"}
                >
                    <FilterIcon width={"40px"} height={"40px"} style={{ cursor: 'pointer' }} />
                </Box>
            </Tooltip>
        </Box>
    );

    const FieldAuxOutName = (key) => (
        <CustomInput
            id="textfield-auxiliaryoutput-name"
            placeholder={t('AccAuxOut001')}

            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            value={filterData.name}
            onKeyDown={handleKeyPress}
            size='small'
            fullWidth
            name='name'
            //label='Person ID'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 50,
            }}

        />
    );

    const FieldDoorName = (key) => (
        <CustomInput
            id="textfield-auxiliaryoutput-doorname"
            placeholder={t('AccDMTopology011')}
            style={{ margin: '2px' }}
            value={filterData.doorName}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='doorName'
            //label='Person ID'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 50,
            }}

        />
    );

    const FieldDeviceName = (key) => (
        <CustomInput
            id="textfield-auxiliaryoutput-devicename"
            placeholder={t('AccDevice014')}

            height={{ xl: "50px", xs: "40px" }}
            padding={{ xl: "0px 20px", xs: "0px 10px" }}
            value={filterData.deviceAlias}
            size='small'
            onKeyDown={handleKeyPress}
            fullWidth
            name='deviceAlias'
            //label='Person ID'
            variant='outlined'
            handleChange={handleChange}
            inputProps={{
                maxLength: 30,
            }}

        />
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitFilter();
        }
    };

    useEffect(() => {
        if (!open && !disabled) {
            refreshTableData();
        }
    }, [open])

    const handlePageChange = (e) => {
        if (filter) {
            setPage(e);
            loadData(e, rowsPerPage, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setPage(e);
        }
    }

    const handlePageSize = (e) => {
        if (filter) {
            setRowsPerPage(e);
            setPage(1);
            loadData(1, e, filterData);
        }
        else {
            setFilter(false);
            setLoad(true);
            setRowsPerPage(e);
            setPage(1);
        }
    }
    const onSelectChecxBoxChange = (id) => {
        setIds(id);
    };
    return (
        <>
            {perListAuxOUT ? <><RightViewLayout title={t("AccAuxOut005")} type='table' actions={actions} navigateBack={true} />
                <Box sx={{ marginTop: { xl: "30px", xs: "15px" }, width: "100%" }}>
                    {open && <FilterComponent
                        firstField={FieldAuxOutName()}
                        // children={FieldDoorName()}
                        secondField={FieldDeviceName()}
                        clearFilter={clearFilter}
                        handleSubmit={handleSubmitFilter}
                        disabled={disabled}
                    />}
                    <DataTable
                        page={page - 1}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        columns={columns}
                        getRowId={(row) => row.aux_id}
                        rows={auxOut}
                        checkboxSelection={true}
                        onSelection={(e) => onSelectChecxBoxChange(e)}
                        rowSelectionModel={ids}
                        onPageChange={(e) => handlePageChange(e + 1)}
                        onPageSizeChange={(e) => handlePageSize(e)}
                        noRecordText={t("AccAuxOut006")} />
                </Box>
            </> : (<Box marginTop={'150px'} width={'100%'}><Typography textAlign={'center'}>{t("AccAuxOut007")}</Typography></Box>)}
        </>
    );
}

export default AuxiliaryOutput;